import React from 'react';

interface ReferenceInputProps {
  value: string;
  onChange: (value: string) => void;
}

export function ReferenceInput({ value, onChange }: ReferenceInputProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Référence / N° de mandat
      </label>
      <input
        type="text"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Ex: MAND-2024-001"
      />
    </div>
  );
}