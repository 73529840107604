import { create } from 'zustand';
import { Client } from '../types/client';
import {
  getClientsByAgency,
  addClient as addClientToFirebase,
  updateClient as updateClientInFirebase,
  deleteClient as deleteClientFromFirebase,
} from '../lib/clients';
import { useAuthStore } from './authStore';

interface ClientStore {
  clients: Client[];
  loading: boolean;
  error: string | null;
  initialized: boolean;
  fetchClients: (agencyId: string) => Promise<void>;
  addClient: (client: Omit<Client, 'id' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  updateClient: (id: string, client: Partial<Client>) => Promise<void>;
  deleteClient: (id: string) => Promise<void>;
}

export const useClientStore = create<ClientStore>((set) => ({
  clients: [],
  loading: false,
  error: null,
  initialized: false,

  fetchClients: async (agencyId: string) => {
    if (!agencyId) return;
    
    set({ loading: true, error: null });
    try {
      const clients = await getClientsByAgency(agencyId);
      set({ 
        clients, 
        loading: false, 
        error: null,
        initialized: true 
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage, loading: false });
    }
  },

  addClient: async (clientData) => {
    const user = useAuthStore.getState().user;
    if (!user?.id) throw new Error('User not authenticated');

    set({ loading: true, error: null });
    try {
      const client = {
        ...clientData,
        agencyId: user.id,
      };
      const id = await addClientToFirebase(client as Client);
      set((state) => ({
        clients: [...state.clients, { ...client, id } as Client],
        loading: false,
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage, loading: false });
      throw error;
    }
  },

  updateClient: async (id, clientData) => {
    set({ loading: true, error: null });
    try {
      await updateClientInFirebase(id, clientData);
      set((state) => ({
        clients: state.clients.map((client) =>
          client.id === id ? { ...client, ...clientData } : client
        ),
        loading: false,
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage, loading: false });
      throw error;
    }
  },

  deleteClient: async (id) => {
    set({ loading: true, error: null });
    try {
      await deleteClientFromFirebase(id);
      set((state) => ({
        clients: state.clients.filter((client) => client.id !== id),
        loading: false,
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage, loading: false });
      throw error;
    }
  },
}));