import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientForm } from '../components/ClientForm';
import { useClientStore } from '../store/clientStore';
import { Client } from '../types/client';

export function NewClientPage() {
  const navigate = useNavigate();
  const addClient = useClientStore((state) => state.addClient);
  
  const handleSubmit = (client: Client) => {
    addClient(client);
    navigate('/clients');
  };
  
  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Nouveau client</h1>
      <ClientForm onSubmit={handleSubmit} />
    </div>
  );
}