import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from './firebase';
import { User } from '../types/user';

const auth = getAuth();

export async function signIn(email: string, password: string): Promise<User> {
  try {
    const credential = await signInWithEmailAndPassword(auth, email, password);
    const userDoc = await getDoc(doc(db, 'users', credential.user.uid));
    
    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé dans la base de données');
    }
    
    const userData = userDoc.data();
    if (!userData.isActive) {
      throw new Error('Compte désactivé. Veuillez contacter l\'administrateur.');
    }
    
    return { id: credential.user.uid, ...userData } as User;
  } catch (error) {
    if (error instanceof Error) {
      switch (error.message) {
        case 'Firebase: Error (auth/invalid-email).':
          throw new Error('Email invalide');
        case 'Firebase: Error (auth/user-not-found).':
          throw new Error('Utilisateur non trouvé');
        case 'Firebase: Error (auth/wrong-password).':
          throw new Error('Mot de passe incorrect');
        case 'Firebase: Error (auth/too-many-requests).':
          throw new Error('Trop de tentatives. Veuillez réessayer plus tard.');
        default:
          throw new Error('Erreur de connexion. Veuillez réessayer.');
      }
    }
    throw error;
  }
}

export async function signUp(
  email: string,
  password: string,
  userData: Partial<User>
): Promise<User> {
  try {
    const credential = await createUserWithEmailAndPassword(auth, email, password);
    const user: User = {
      id: credential.user.uid,
      email,
      isActive: true,
      createdAt: new Date(),
      updatedAt: new Date(),
      ...userData,
    };

    // Créer le document utilisateur
    await setDoc(doc(db, 'users', user.id), {
      ...user,
      createdAt: Timestamp.fromDate(new Date()),
      updatedAt: Timestamp.fromDate(new Date()),
    });

    // Si c'est une agence, créer aussi le document agence
    if (user.role === 'agency') {
      await setDoc(doc(db, 'agencies', user.id), {
        name: `Agence ${user.firstName} ${user.lastName}`,
        type: 'traditional',
        email: user.email,
        isActive: true,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
      });
    }

    return user;
  } catch (error) {
    if (error instanceof Error) {
      switch (error.message) {
        case 'Firebase: Error (auth/email-already-in-use).':
          throw new Error('Cet email est déjà utilisé');
        case 'Firebase: Error (auth/invalid-email).':
          throw new Error('Email invalide');
        case 'Firebase: Error (auth/weak-password).':
          throw new Error('Le mot de passe doit contenir au moins 6 caractères');
        default:
          throw new Error('Erreur lors de la création du compte');
      }
    }
    throw error;
  }
}

export async function signOut(): Promise<void> {
  await firebaseSignOut(auth);
}

export async function getCurrentUser(): Promise<User | null> {
  return new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      unsubscribe();
      if (firebaseUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (!userData.isActive) {
              await signOut();
              resolve(null);
              return;
            }
            resolve({ id: firebaseUser.uid, ...userData } as User);
          } else {
            await signOut();
            resolve(null);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          resolve(null);
        }
      } else {
        resolve(null);
      }
    });
  });
}

export async function updateUserProfile(data: Partial<User>): Promise<User> {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('Aucun utilisateur connecté');

  const userRef = doc(db, 'users', currentUser.uid);
  await updateDoc(userRef, { 
    ...data, 
    updatedAt: Timestamp.fromDate(new Date()) 
  });

  const userDoc = await getDoc(userRef);
  return { id: currentUser.uid, ...userDoc.data() } as User;
}