import React, { useState } from 'react';
import { useAdminStore } from '../../store/adminStore';
import { Portal, PortalType } from '../../types/portal';
import { LoadingSpinner } from '../LoadingSpinner';
import { Globe, Check, X, RefreshCw, Key } from 'lucide-react';

const PORTALS: Portal[] = [
  { id: 'seloger', name: 'SeLoger', active: false },
  { id: 'leboncoin', name: 'Leboncoin', active: false },
  { id: 'logicimmo', name: 'Logic-Immo', active: false },
  { id: 'bienici', name: "Bien'ici", active: false },
  { id: 'superimmo', name: 'Superimmo', active: false },
  { id: 'monbien', name: 'monbien.fr', active: false },
  { id: 'repimmo', name: 'Repimmo', active: false },
  { id: 'etreproprio', name: 'etreproprio.com', active: false },
  { id: 'goflint', name: 'Goflint.fr', active: false },
];

export function PortalGateway() {
  const [selectedPortal, setSelectedPortal] = useState<Portal | null>(null);
  const [isSyncing, setIsSyncing] = useState(false);

  const handleSync = async (portal: Portal) => {
    setIsSyncing(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 2000));
      alert(`Synchronisation avec ${portal.name} effectuée avec succès`);
    } catch (error) {
      console.error('Error syncing with portal:', error);
      alert(`Erreur lors de la synchronisation avec ${portal.name}`);
    } finally {
      setIsSyncing(false);
    }
  };

  const handleConfigurePortal = (portal: Portal) => {
    setSelectedPortal(portal);
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold flex items-center gap-2 text-gray-900">
          <Globe className="w-7 h-7 text-blue-600" />
          Passerelle Portails
        </h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {PORTALS.map((portal) => (
          <div
            key={portal.id}
            className="bg-white rounded-xl shadow-md p-6 hover:shadow-lg transition-shadow"
          >
            <div className="flex items-center gap-4 mb-4">
              <h3 className="text-lg font-medium">{portal.name}</h3>
            </div>

            <div className="flex items-center gap-2 mb-4">
              <span className={`w-2 h-2 rounded-full ${
                portal.active ? 'bg-green-500' : 'bg-red-500'
              }`} />
              <span className="text-sm text-gray-600">
                {portal.active ? 'Connecté' : 'Non connecté'}
              </span>
            </div>

            <div className="space-y-2">
              <button
                onClick={() => handleConfigurePortal(portal)}
                className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
              >
                <Key className="w-4 h-4" />
                Configurer
              </button>
              
              <button
                onClick={() => handleSync(portal)}
                disabled={!portal.active || isSyncing}
                className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 transition-colors"
              >
                {isSyncing ? (
                  <LoadingSpinner size="sm" color="white" />
                ) : (
                  <RefreshCw className="w-4 h-4" />
                )}
                Synchroniser
              </button>
            </div>
          </div>
        ))}
      </div>

      {selectedPortal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 backdrop-blur-sm">
          <div className="bg-white rounded-xl shadow-xl max-w-md w-full">
            <div className="flex items-center justify-between p-4 border-b">
              <h3 className="text-lg font-medium">Configuration {selectedPortal.name}</h3>
              <button
                onClick={() => setSelectedPortal(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="p-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Clé API
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Entrez votre clé API"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Identifiant
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Entrez votre identifiant"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Mot de passe
                </label>
                <input
                  type="password"
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Entrez votre mot de passe"
                />
              </div>
            </div>

            <div className="flex justify-end gap-3 p-4 border-t">
              <button
                onClick={() => setSelectedPortal(null)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                Annuler
              </button>
              <button
                onClick={() => {
                  setSelectedPortal(null);
                }}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}