import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useAuthStore } from './store/authStore';
import { AppRoutes } from './routes/AppRoutes';
import { LoadingScreen } from './components/layout/LoadingScreen';

function App() {
  const { initialized, loading } = useAuthStore();

  if (!initialized || loading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;