import React, { useState } from 'react';
import { Property } from '../types/property';
import { Client } from '../types/client';
import { X, Mail, Send } from 'lucide-react';
import { LoadingSpinner } from './LoadingSpinner';

interface PropertyShareModalProps {
  property: Property;
  client: Client;
  isOpen: boolean;
  onClose: () => void;
}

export function PropertyShareModal({ property, client, isOpen, onClose }: PropertyShareModalProps) {
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState(
    `Bonjour ${client.firstName},\n\nJe pense que ce bien pourrait vous intéresser :\n\n${property.title}\n\nCordialement,`
  );

  const handleSend = async () => {
    setSending(true);
    try {
      // TODO: Implement email sending functionality
      await new Promise(resolve => setTimeout(resolve, 1000));
      onClose();
    } catch (error) {
      console.error('Error sending email:', error);
    } finally {
      setSending(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-lg font-semibold flex items-center">
            <Mail className="w-5 h-5 mr-2" />
            Envoyer la fiche au client
          </h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-4">
          <div className="mb-4">
            <div className="text-sm font-medium text-gray-700 mb-1">Destinataire</div>
            <div className="text-gray-900">{client.firstName} {client.lastName}</div>
            <div className="text-gray-600 text-sm">{client.email}</div>
          </div>

          <div className="mb-4">
            <div className="text-sm font-medium text-gray-700 mb-1">Bien</div>
            <div className="text-gray-900">{property.title}</div>
            <div className="text-gray-600 text-sm">{property.address.city}, {property.address.postalCode}</div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Message
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={6}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className="flex justify-end gap-3 p-4 border-t">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Annuler
          </button>
          <button
            onClick={handleSend}
            disabled={sending}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50"
          >
            {sending ? (
              <>
                <LoadingSpinner size="sm" color="white" />
                Envoi en cours...
              </>
            ) : (
              <>
                <Send className="w-4 h-4" />
                Envoyer
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}