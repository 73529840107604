import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs, query, where, Timestamp } from 'firebase/firestore';
import { db } from './firebase';
import { Agent } from '../types/agent';

const AGENTS_COLLECTION = 'agents';

export async function getAgentsByAgency(agencyId: string): Promise<Agent[]> {
  const q = query(collection(db, AGENTS_COLLECTION), where('agencyId', '==', agencyId));
  const snapshot = await getDocs(q);
  
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    createdAt: doc.data().createdAt?.toDate(),
    updatedAt: doc.data().updatedAt?.toDate(),
  } as Agent));
}

export async function addAgent(agent: Omit<Agent, 'id'>): Promise<string> {
  const agentData = {
    ...agent,
    createdAt: Timestamp.fromDate(new Date()),
    updatedAt: Timestamp.fromDate(new Date()),
  };
  
  const docRef = await addDoc(collection(db, AGENTS_COLLECTION), agentData);
  return docRef.id;
}

export async function updateAgent(id: string, agent: Partial<Agent>): Promise<void> {
  const agentRef = doc(db, AGENTS_COLLECTION, id);
  await updateDoc(agentRef, {
    ...agent,
    updatedAt: Timestamp.fromDate(new Date()),
  });
}

export async function deleteAgent(id: string): Promise<void> {
  const agentRef = doc(db, AGENTS_COLLECTION, id);
  await deleteDoc(agentRef);
}