export function formatNumber(value: number): string {
  return new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value).replace(/\s/g, ' ');
}

export function formatPrice(value: number): string {
  return new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value).replace(/\s/g, ' ');
}

export function formatCurrency(value: number): string {
  return `${formatPrice(value)} €`;
}

export function formatPercentage(value: number): string {
  return `${value}%`;
}

export function formatYears(years: number[]): string {
  return years.join(' ');
}

export function formatDistanceToNow(date: Date): string {
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  if (diffInSeconds < 60) return 'moins d\'une minute';
  
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''}`;
  
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) return `${diffInHours} heure${diffInHours > 1 ? 's' : ''}`;
  
  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 30) return `${diffInDays} jour${diffInDays > 1 ? 's' : ''}`;
  
  const diffInMonths = Math.floor(diffInDays / 30);
  if (diffInMonths < 12) return `${diffInMonths} mois`;
  
  const diffInYears = Math.floor(diffInDays / 365);
  return `${diffInYears} an${diffInYears > 1 ? 's' : ''}`;
}