import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from './firebase';

export async function uploadImage(file: File): Promise<string> {
  const storageRef = ref(storage, `properties/${Date.now()}_${file.name}`);
  const snapshot = await uploadBytes(storageRef, file);
  return getDownloadURL(snapshot.ref);
}

export async function uploadImages(files: File[]): Promise<string[]> {
  const uploadPromises = files.map(uploadImage);
  return Promise.all(uploadPromises);
}