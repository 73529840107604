import { EnergyClass } from '../types/property';

interface EnergyRange {
  min: number;
  max: number;
  class: EnergyClass;
}

export const DPE_RANGES: EnergyRange[] = [
  { min: 0, max: 50, class: 'A' },
  { min: 51, max: 90, class: 'B' },
  { min: 91, max: 150, class: 'C' },
  { min: 151, max: 230, class: 'D' },
  { min: 231, max: 330, class: 'E' },
  { min: 331, max: 450, class: 'F' },
  { min: 451, max: Infinity, class: 'G' }
];

export const GES_RANGES: EnergyRange[] = [
  { min: 0, max: 5, class: 'A' },
  { min: 6, max: 10, class: 'B' },
  { min: 11, max: 20, class: 'C' },
  { min: 21, max: 35, class: 'D' },
  { min: 36, max: 55, class: 'E' },
  { min: 56, max: 80, class: 'F' },
  { min: 81, max: Infinity, class: 'G' }
];

export function getDPERange(class_: EnergyClass): string {
  const range = DPE_RANGES.find(r => r.class === class_);
  if (!range) return '';
  return range.max === Infinity ? `> ${range.min}` : `${range.min} à ${range.max}`;
}

export function getGESRange(class_: EnergyClass): string {
  const range = GES_RANGES.find(r => r.class === class_);
  if (!range) return '';
  return range.max === Infinity ? `> ${range.min}` : `${range.min} à ${range.max}`;
}