import { create } from 'zustand';
import { Property } from '../types/property';
import { 
  addProperty as addPropertyToFirebase,
  updateProperty as updatePropertyInFirebase,
  deleteProperty as deletePropertyFromFirebase,
  getProperties,
} from '../lib/properties';
import { uploadImages } from '../lib/storage';
import { getNetworkStatus } from '../lib/firebase';
import { useAuthStore } from './authStore';

interface PropertyStore {
  properties: Property[];
  loading: boolean;
  error: string | null;
  initialized: boolean;
  offline: boolean;
  pendingChanges: Array<{
    type: 'add' | 'update' | 'delete';
    data: any;
  }>;
  fetchProperties: (userId: string) => Promise<void>;
  addProperty: (property: Omit<Property, 'id' | 'createdAt' | 'updatedAt'>) => Promise<string>;
  updateProperty: (id: string, property: Partial<Property>) => Promise<void>;
  deleteProperty: (id: string) => Promise<void>;
  uploadPropertyImages: (files: File[]) => Promise<string[]>;
  setProperties: (properties: Property[]) => void;
  syncPendingChanges: () => Promise<void>;
}

export const usePropertyStore = create<PropertyStore>((set, get) => ({
  properties: [],
  loading: true,
  error: null,
  initialized: false,
  offline: false,
  pendingChanges: [],

  setProperties: (properties) => set({ properties }),

  fetchProperties: async (userId: string) => {
    if (!userId) return;
    
    set({ loading: true, error: null });
    try {
      const properties = await getProperties(userId);
      set({ 
        properties, 
        initialized: true,
        loading: false,
        error: null
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Une erreur est survenue';
      set({ 
        error: errorMessage,
        loading: false,
        offline: !getNetworkStatus()
      });
    }
  },

  addProperty: async (property) => {
    const user = useAuthStore.getState().user;
    if (!user?.id) throw new Error('Utilisateur non authentifié');

    try {
      if (!getNetworkStatus()) {
        set(state => ({
          pendingChanges: [...state.pendingChanges, { type: 'add', data: property }],
          offline: true
        }));
        return 'pending';
      }

      const propertyWithUser = {
        ...property,
        userId: user.id,
        agentId: user.id
      };

      const id = await addPropertyToFirebase(propertyWithUser as Property);
      
      // Update local state
      set(state => ({
        properties: [...state.properties, { ...propertyWithUser, id } as Property]
      }));
      
      return id;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Une erreur est survenue';
      set({ 
        error: errorMessage,
        offline: !getNetworkStatus()
      });
      throw error;
    }
  },

  updateProperty: async (id: string, property: Partial<Property>) => {
    const user = useAuthStore.getState().user;
    if (!user?.id) throw new Error('Utilisateur non authentifié');

    try {
      if (!getNetworkStatus()) {
        set(state => ({
          pendingChanges: [...state.pendingChanges, { type: 'update', data: { id, ...property } }],
          offline: true
        }));
        return;
      }

      await updatePropertyInFirebase(id, property);
      
      // Update local state
      set(state => ({
        properties: state.properties.map(p => 
          p.id === id ? { ...p, ...property } : p
        )
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Une erreur est survenue';
      set({ 
        error: errorMessage,
        offline: !getNetworkStatus()
      });
      throw error;
    }
  },

  deleteProperty: async (id: string) => {
    const user = useAuthStore.getState().user;
    if (!user?.id) throw new Error('Utilisateur non authentifié');

    try {
      if (!getNetworkStatus()) {
        set(state => ({
          pendingChanges: [...state.pendingChanges, { type: 'delete', data: { id } }],
          offline: true
        }));
        return;
      }

      await deletePropertyFromFirebase(id);
      
      // Update local state
      set(state => ({
        properties: state.properties.filter(p => p.id !== id)
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Une erreur est survenue';
      set({ 
        error: errorMessage,
        offline: !getNetworkStatus()
      });
      throw error;
    }
  },

  uploadPropertyImages: async (files: File[]) => {
    try {
      if (!getNetworkStatus()) {
        throw new Error('Impossible de télécharger des images en mode hors ligne');
      }

      const urls = await uploadImages(files);
      return urls;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Une erreur est survenue';
      set({ 
        error: errorMessage,
        offline: !getNetworkStatus()
      });
      throw error;
    }
  },

  syncPendingChanges: async () => {
    const { pendingChanges } = get();
    const user = useAuthStore.getState().user;
    if (!user?.id || pendingChanges.length === 0) return;

    try {
      for (const change of pendingChanges) {
        switch (change.type) {
          case 'add':
            const propertyWithUser = {
              ...change.data,
              userId: user.id,
              agentId: user.id
            };
            await addPropertyToFirebase(propertyWithUser as Property);
            break;
          case 'update':
            const { id, ...updateData } = change.data;
            await updatePropertyInFirebase(id, updateData);
            break;
          case 'delete':
            await deletePropertyFromFirebase(change.data.id);
            break;
        }
      }

      set({ pendingChanges: [], offline: false });
      
      // Refresh properties after sync
      await get().fetchProperties(user.id);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Une erreur est survenue';
      set({ error: errorMessage });
    }
  }
}));

// Set up auto-sync when coming back online
if (typeof window !== 'undefined') {
  window.addEventListener('online', () => {
    usePropertyStore.getState().syncPendingChanges();
  });
}