import { initializeApp } from 'firebase/app';
import { getFirestore, enableIndexedDbPersistence, initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCjDik58DsmNaNfnLtBj-ft8gTh24UbOgU",
  authDomain: "immobilier-22c01.firebaseapp.com",
  projectId: "immobilier-22c01",
  storageBucket: "immobilier-22c01.appspot.com",
  messagingSenderId: "834657820937",
  appId: "1:834657820937:web:9248a37da2834f1af633d8",
  measurementId: "G-F67TS83VQE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore with persistent cache
const db = initializeFirestore(app, {
  cache: persistentLocalCache({
    tabManager: persistentMultipleTabManager()
  })
});

export const auth = getAuth(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

// Add network status monitoring
let isOnline = true;

window.addEventListener('online', () => {
  isOnline = true;
  console.log('App is online');
});

window.addEventListener('offline', () => {
  isOnline = false;
  console.log('App is offline');
});

export function getNetworkStatus() {
  return isOnline;
}

export { db };
export default app;