import React from 'react';
import { LucideIcon } from 'lucide-react';
import { LoadingSpinner } from '../LoadingSpinner';

interface SubmitButtonProps {
  loading: boolean;
  icon: LucideIcon;
  children: React.ReactNode;
}

export function SubmitButton({ loading, icon: Icon, children }: SubmitButtonProps) {
  return (
    <button
      type="submit"
      disabled={loading}
      className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
    >
      {loading ? (
        <>
          <LoadingSpinner size="sm" color="white" />
          <span className="ml-2">Chargement...</span>
        </>
      ) : (
        <>
          <Icon className="w-5 h-5 mr-2" />
          {children}
        </>
      )}
    </button>
  );
}