import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs, Timestamp, query, where, orderBy, onSnapshot, QuerySnapshot, FirestoreError } from 'firebase/firestore';
import { db } from './firebase';
import { Property } from '../types/property';

const PROPERTIES_COLLECTION = 'properties';

export function subscribeToProperties(
  userId: string, 
  onNext: (properties: Property[]) => void,
  onError?: (error: Error) => void
) {
  // Create a compound query with proper ordering and index
  const q = query(
    collection(db, PROPERTIES_COLLECTION),
    where('userId', '==', userId),
    orderBy('createdAt', 'desc'),
    orderBy('__name__', 'desc') // Add this to match the index
  );
  
  // Set up real-time listener with error handling
  return onSnapshot(
    q, 
    (snapshot: QuerySnapshot) => {
      const properties = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
        updatedAt: doc.data().updatedAt?.toDate() || new Date(),
      })) as Property[];
      
      onNext(properties);
    },
    (error: FirestoreError) => {
      console.error('Error in properties subscription:', error);
      if (onError) {
        onError(error);
      }
    }
  );
}

export async function addProperty(property: Omit<Property, 'id'>): Promise<string> {
  const propertyData = {
    ...property,
    createdAt: Timestamp.fromDate(new Date()),
    updatedAt: Timestamp.fromDate(new Date()),
  };
  
  const docRef = await addDoc(collection(db, PROPERTIES_COLLECTION), propertyData);
  return docRef.id;
}

export async function updateProperty(id: string, property: Partial<Property>): Promise<void> {
  const propertyRef = doc(db, PROPERTIES_COLLECTION, id);
  
  // Remove undefined values to prevent Firebase errors
  const cleanedData = Object.entries(property).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);

  // Ensure rooms array is properly formatted
  if (cleanedData.rooms) {
    cleanedData.rooms = cleanedData.rooms.map((room: any) => ({
      id: room.id || String(Date.now()),
      name: room.name || '',
      type: room.type || 'ground_floor',
      surface: Number(room.surface) || 0,
      description: room.description || ''
    }));
  }

  // Add timestamp
  cleanedData.updatedAt = Timestamp.fromDate(new Date());

  await updateDoc(propertyRef, cleanedData);
}

export async function deleteProperty(id: string): Promise<void> {
  const propertyRef = doc(db, PROPERTIES_COLLECTION, id);
  await deleteDoc(propertyRef);
}

export async function getProperties(userId: string): Promise<Property[]> {
  const q = query(
    collection(db, PROPERTIES_COLLECTION),
    where('userId', '==', userId),
    orderBy('createdAt', 'desc'),
    orderBy('__name__', 'desc') // Add this to match the index
  );
  
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    createdAt: doc.data().createdAt?.toDate() || new Date(),
    updatedAt: doc.data().updatedAt?.toDate() || new Date(),
  })) as Property[];
}