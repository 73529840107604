import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { SignInPage } from '../pages/auth/SignInPage';
import { SignUpPage } from '../pages/auth/SignUpPage';
import { PrivateRoute } from '../components/auth/PrivateRoute';
import { DashboardPage } from '../pages/admin/DashboardPage';
import { UserDashboard } from '../pages/UserDashboard';

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<SignInPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      
      <Route
        path="/admin/*"
        element={
          <PrivateRoute requiredRole="admin">
            <DashboardPage />
          </PrivateRoute>
        }
      />
      
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <UserDashboard />
          </PrivateRoute>
        }
      />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}