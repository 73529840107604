import React, { useState } from 'react';
import { Image as ImageIcon, X, Upload, MoveLeft, MoveRight } from 'lucide-react';
import { LoadingSpinner } from '../LoadingSpinner';

interface ImageUploadProps {
  images: string[];
  onChange: (images: string[]) => void;
  maxImages?: number;
}

export function ImageUpload({ images, onChange, maxImages = 7 }: ImageUploadProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState<{ [key: string]: boolean }>({});

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files).filter(file => 
      file.type.startsWith('image/') && file.size <= 5 * 1024 * 1024
    );

    if (files.length + images.length > maxImages) {
      alert(`Vous ne pouvez pas télécharger plus de ${maxImages} images`);
      return;
    }

    handleFiles(files);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []).filter(file => 
      file.type.startsWith('image/') && file.size <= 5 * 1024 * 1024
    );

    if (files.length + images.length > maxImages) {
      alert(`Vous ne pouvez pas télécharger plus de ${maxImages} images`);
      return;
    }

    handleFiles(files);
    e.target.value = ''; // Reset input
  };

  const handleFiles = async (files: File[]) => {
    const newUploadingFiles = { ...uploadingFiles };
    files.forEach(file => {
      newUploadingFiles[file.name] = true;
    });
    setUploadingFiles(newUploadingFiles);

    try {
      const newImages = await Promise.all(
        files.map(file => {
          return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              // Simulate network delay to prevent overwhelming the browser
              setTimeout(() => {
                resolve(reader.result as string);
              }, 500);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        })
      );

      onChange([...images, ...newImages]);
    } catch (error) {
      console.error('Error uploading images:', error);
      alert('Une erreur est survenue lors du téléchargement des images');
    } finally {
      setUploadingFiles({});
    }
  };

  const removeImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    onChange(newImages);
  };

  const moveImage = (index: number, direction: 'left' | 'right') => {
    if (
      (direction === 'left' && index === 0) ||
      (direction === 'right' && index === images.length - 1)
    ) {
      return;
    }

    const newImages = [...images];
    const newIndex = direction === 'left' ? index - 1 : index + 1;
    [newImages[index], newImages[newIndex]] = [newImages[newIndex], newImages[index]];
    onChange(newImages);
  };

  const isUploading = Object.values(uploadingFiles).some(value => value);

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <label className="block text-sm font-medium text-gray-700">Photos du bien</label>
        <span className="text-sm text-gray-500">
          {images.length}/{maxImages} photos
        </span>
      </div>

      <div
        className={`grid grid-cols-2 md:grid-cols-4 gap-4 ${
          isDragging ? 'bg-blue-50 border-2 border-blue-300 border-dashed p-4 rounded-lg' : ''
        }`}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {images.map((image, index) => (
          <div key={index} className="relative group aspect-[4/3]">
            <img
              src={image}
              alt={`Photo ${index + 1}`}
              className="w-full h-full object-cover rounded-lg"
            />
            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity rounded-lg">
              <div className="absolute top-2 right-2 flex gap-1">
                <button
                  type="button"
                  onClick={() => removeImage(index)}
                  className="p-1 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity hover:bg-red-600"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
              <div className="absolute bottom-2 right-2 flex gap-1">
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => moveImage(index, 'left')}
                    className="p-1 bg-white text-gray-700 rounded-full opacity-0 group-hover:opacity-100 transition-opacity hover:bg-gray-100"
                  >
                    <MoveLeft className="w-4 h-4" />
                  </button>
                )}
                {index < images.length - 1 && (
                  <button
                    type="button"
                    onClick={() => moveImage(index, 'right')}
                    className="p-1 bg-white text-gray-700 rounded-full opacity-0 group-hover:opacity-100 transition-opacity hover:bg-gray-100"
                  >
                    <MoveRight className="w-4 h-4" />
                  </button>
                )}
              </div>
              {index === 0 && (
                <div className="absolute bottom-2 left-2">
                  <span className="px-2 py-1 bg-blue-600 text-white text-xs rounded-md">
                    Photo principale
                  </span>
                </div>
              )}
            </div>
          </div>
        ))}

        {images.length < maxImages && (
          <label className="relative flex flex-col items-center justify-center aspect-[4/3] border-2 border-dashed border-gray-300 rounded-lg hover:border-gray-400 cursor-pointer bg-gray-50 hover:bg-gray-100 transition-colors">
            {isUploading ? (
              <div className="flex flex-col items-center gap-2">
                <LoadingSpinner />
                <span className="text-sm text-gray-500">Chargement...</span>
              </div>
            ) : (
              <>
                <Upload className="w-8 h-8 text-gray-400" />
                <div className="mt-2 text-center">
                  <span className="text-sm text-gray-500">
                    Glissez-déposez des photos ou cliquez pour sélectionner
                  </span>
                  <p className="text-xs text-gray-400 mt-1">
                    PNG, JPG jusqu'à 5MB
                  </p>
                </div>
              </>
            )}
            <input
              type="file"
              className="hidden"
              accept="image/*"
              multiple
              onChange={handleFileChange}
              disabled={isUploading}
            />
          </label>
        )}
      </div>

      <div className="text-sm text-gray-500">
        <p>La première photo sera utilisée comme photo principale</p>
        <p>Formats acceptés : JPG, PNG</p>
        <p>Taille maximale : 5 MB par image</p>
      </div>
    </div>
  );
}