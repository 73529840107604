import React from 'react';
import { EnergyClass } from '../../types/property';
import { getDPEColor, getGESColor } from '../../utils/dpeColors';
import { getDPERange, getGESRange } from '../../utils/dpeRanges';

interface DPEDisplayProps {
  type: 'dpe' | 'ges';
  currentClass: EnergyClass;
  currentValue: number;
}

export function DPEDisplay({ type, currentClass, currentValue }: DPEDisplayProps) {
  const getTitle = () => {
    if (type === 'dpe') {
      return {
        top: 'Logement économe',
        bottom: 'Logement énergivore',
        unit: 'kWhEP/m².an',
        value: currentValue ? `${currentValue} kWhEP/m².an` : ''
      };
    }
    return {
      top: 'Faible émission de GES',
      bottom: 'Forte émission de GES',
      unit: 'kgeqCO2/m².an',
      value: currentValue ? `${currentValue} kgeqCO2/m².an` : ''
    };
  };

  const title = getTitle();
  const getColorFn = type === 'dpe' ? getDPEColor : getGESColor;
  const getRangeFn = type === 'dpe' ? getDPERange : getGESRange;

  return (
    <div className="space-y-1">
      <div className="text-xs font-medium text-gray-700">{title.top}</div>
      <div className="space-y-[2px]">
        {(['A', 'B', 'C', 'D', 'E', 'F', 'G'] as EnergyClass[]).map((letter) => (
          <div
            key={letter}
            className={`flex items-center ${letter === currentClass ? 'opacity-100' : 'opacity-70'}`}
          >
            <div
              className="w-10 h-7 flex items-center justify-center text-white font-bold text-sm"
              style={{
                backgroundColor: getColorFn(letter),
                clipPath: type === 'dpe' ? 'polygon(0 0, 85% 0, 100% 50%, 85% 100%, 0 100%)' : 'none',
                borderRadius: type === 'ges' ? '4px 0 0 4px' : 'none'
              }}
            >
              {letter}
            </div>
            <div className="flex-1 h-7 bg-gray-100 flex items-center px-2 text-xs text-gray-600">
              {getRangeFn(letter)} {title.unit}
            </div>
          </div>
        ))}
      </div>
      <div className="text-xs font-medium text-gray-700">{title.bottom}</div>
      {title.value && (
        <div className="mt-2 text-sm font-medium text-gray-900">
          Valeur actuelle : {title.value}
        </div>
      )}
    </div>
  );
}