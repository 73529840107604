import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import { getDefaultRoute } from '../utils/routeUtils';

export function useAuth() {
  const { user, loading, initialized, error } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (initialized && !loading) {
      if (!user) {
        // Only redirect to login if we're not already there
        if (!location.pathname.includes('/login')) {
          navigate('/login', { 
            state: { from: location },
            replace: true 
          });
        }
      } else {
        // If user is authenticated and on login page, redirect to appropriate dashboard
        if (location.pathname === '/login') {
          const defaultRoute = getDefaultRoute(user);
          navigate(defaultRoute, { replace: true });
        }
      }
    }
  }, [initialized, loading, user, navigate, location]);

  return {
    user,
    loading,
    initialized,
    error,
    isAdmin: user?.role === 'admin',
    isAuthenticated: !!user,
  };
}