import React from 'react';
import { AgencyProfileForm } from '../components/agency/AgencyProfileForm';

export function AgencyProfilePage() {
  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Profil de l'agence</h1>
      <AgencyProfileForm />
    </div>
  );
}