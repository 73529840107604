import React from 'react';
import { Property } from '../types/property';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { useAgencyStore } from '../store/agencyStore';
import { useAuthStore } from '../store/authStore';
import { AgencyHeader } from './PDFGenerator/AgencyHeader';
import { PropertyDetails } from './PDFGenerator/PropertyDetails';
import { EnergyInfo } from './PDFGenerator/EnergyInfo';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
    textAlign: 'center',
  },
  mainImage: {
    width: '100%',
    height: 300,
    objectFit: 'cover',
    marginBottom: 20,
  },
  galleryGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
  },
  galleryImage: {
    width: '31%',
    height: 120,
    objectFit: 'cover',
  },
  disclaimer: {
    marginTop: 20,
    fontSize: 8,
    color: '#9CA3AF',
    textAlign: 'center',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#9CA3AF',
  },
});

interface PropertyPDFProps {
  property: Property;
}

export function PropertyPDF({ property }: PropertyPDFProps) {
  const { user } = useAuthStore();
  const { agency, fetchAgency } = useAgencyStore();

  React.useEffect(() => {
    if (user?.id) {
      fetchAgency(user.id);
    }
  }, [user?.id, fetchAgency]);

  const PropertyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        {agency && <AgencyHeader agency={agency} />}
        
        <Text style={styles.title}>{property.title}</Text>
        
        {property.images.length > 0 && (
          <Image src={property.images[0]} style={styles.mainImage} />
        )}

        <PropertyDetails property={property} />
        <EnergyInfo property={property} />

        <Text style={styles.pageNumber}>1/2</Text>
      </Page>

      {property.images.length > 1 && (
        <Page size="A4" style={styles.page}>
          <View style={styles.galleryGrid}>
            {property.images.slice(1).map((image, index) => (
              <Image key={index} src={image} style={styles.galleryImage} />
            ))}
          </View>

          <Text style={styles.disclaimer}>
            Document non contractuel. Les informations et photographies sont données à titre indicatif.
          </Text>

          <Text style={styles.pageNumber}>2/2</Text>
        </Page>
      )}
    </Document>
  );

  return (
    <div className="space-y-4">
      <PDFViewer style={{ width: '100%', height: '600px' }}>
        <PropertyDocument />
      </PDFViewer>
    </div>
  );
}