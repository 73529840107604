import { create } from 'zustand';
import { Agent } from '../types/agent';
import {
  getAgentsByAgency,
  addAgent as addAgentToFirebase,
  updateAgent as updateAgentInFirebase,
  deleteAgent as deleteAgentFromFirebase,
} from '../lib/agents';

interface AgentStore {
  agents: Agent[];
  loading: boolean;
  error: string | null;
  fetchAgents: (agencyId: string) => Promise<void>;
  addAgent: (agent: Omit<Agent, 'id' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  updateAgent: (id: string, agent: Partial<Agent>) => Promise<void>;
  deleteAgent: (id: string) => Promise<void>;
}

export const useAgentStore = create<AgentStore>((set) => ({
  agents: [],
  loading: false,
  error: null,

  fetchAgents: async (agencyId) => {
    set({ loading: true, error: null });
    try {
      const agents = await getAgentsByAgency(agencyId);
      set({ agents });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  addAgent: async (agent) => {
    set({ loading: true, error: null });
    try {
      const id = await addAgentToFirebase(agent as Agent);
      set((state) => ({
        agents: [...state.agents, { ...agent, id } as Agent],
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  updateAgent: async (id, agent) => {
    set({ loading: true, error: null });
    try {
      await updateAgentInFirebase(id, agent);
      set((state) => ({
        agents: state.agents.map((a) =>
          a.id === id ? { ...a, ...agent } : a
        ),
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  deleteAgent: async (id) => {
    set({ loading: true, error: null });
    try {
      await deleteAgentFromFirebase(id);
      set((state) => ({
        agents: state.agents.filter((a) => a.id !== id),
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage });
      throw error;
    } finally {
      set({ loading: false });
    }
  },
}));