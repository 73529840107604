import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PropertyCard } from '../components/PropertyCard';
import { usePropertyStore } from '../store/propertyStore';
import { useAuthStore } from '../store/authStore';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { Building2, Plus } from 'lucide-react';

export function PropertiesPage() {
  const { user } = useAuthStore();
  const { properties, loading, error, fetchProperties } = usePropertyStore();
  
  useEffect(() => {
    if (user?.id) {
      fetchProperties(user.id);
    }
  }, [user?.id, fetchProperties]);
  
  if (loading && (!properties || properties.length === 0)) {
    return (
      <div className="flex justify-center items-center h-64">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
        Une erreur est survenue lors du chargement des biens : {error}
      </div>
    );
  }
  
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold flex items-center gap-2">
          <Building2 className="w-7 h-7 text-blue-600" />
          Biens immobiliers
        </h1>
        <Link
          to="/property/new"
          className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Plus className="w-5 h-5" />
          Nouveau bien
        </Link>
      </div>
      
      {(!properties || properties.length === 0) ? (
        <div className="text-center py-12 bg-white rounded-lg shadow-md">
          <Building2 className="w-16 h-16 mx-auto text-gray-400 mb-4" />
          <p className="text-gray-600 mb-2">Aucun bien immobilier n'a été ajouté.</p>
          <p className="text-gray-500">
            Commencez par ajouter votre premier bien en cliquant sur "Nouveau bien".
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {properties.map((property) => (
            <PropertyCard
              key={property.id}
              property={property}
            />
          ))}
        </div>
      )}
    </div>
  );
}