import { collection, doc, getDoc, setDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from './firebase';
import { SMTPConfig, TestEmailData } from '../types/smtp';
import { functions } from './firebase/functions';
import { httpsCallable } from 'firebase/functions';

const SMTP_DOC_ID = 'smtp-config';

export async function getSMTPConfig(): Promise<SMTPConfig | null> {
  const docRef = doc(db, 'settings', SMTP_DOC_ID);
  const docSnap = await getDoc(docRef);
  
  if (!docSnap.exists()) return null;
  
  return {
    ...docSnap.data(),
    createdAt: docSnap.data().createdAt?.toDate(),
    updatedAt: docSnap.data().updatedAt?.toDate(),
    lastTestStatus: docSnap.data().lastTestStatus ? {
      ...docSnap.data().lastTestStatus,
      testedAt: docSnap.data().lastTestStatus.testedAt?.toDate(),
    } : undefined,
  } as SMTPConfig;
}

export async function updateSMTPConfig(config: Partial<SMTPConfig>): Promise<void> {
  const docRef = doc(db, 'settings', SMTP_DOC_ID);
  const docSnap = await getDoc(docRef);
  
  if (!docSnap.exists()) {
    await setDoc(docRef, {
      ...config,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    });
  } else {
    await setDoc(docRef, {
      ...docSnap.data(),
      ...config,
      updatedAt: Timestamp.now(),
    }, { merge: true });
  }
}

export async function sendTestEmail(data: TestEmailData): Promise<void> {
  const config = await getSMTPConfig();
  if (!config || !config.enabled) {
    throw new Error('Configuration SMTP non activée');
  }

  try {
    const sendTestEmailFn = httpsCallable(functions, 'sendTestEmail');
    await sendTestEmailFn(data);

    // Update test status on success
    const docRef = doc(db, 'settings', SMTP_DOC_ID);
    await updateDoc(docRef, {
      lastTestStatus: {
        success: true,
        message: 'Email test envoyé avec succès',
        testedAt: Timestamp.now(),
      }
    });
  } catch (error) {
    // Update test status on failure
    const docRef = doc(db, 'settings', SMTP_DOC_ID);
    await updateDoc(docRef, {
      lastTestStatus: {
        success: false,
        message: error instanceof Error ? error.message : 'Erreur lors de l\'envoi du mail test',
        testedAt: Timestamp.now(),
      }
    });
    throw error;
  }
}