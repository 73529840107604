import { EnergyClass } from '../types/property';
import { DPE_RANGES, GES_RANGES } from './dpeRanges';

export function calculateDPEClass(value: number): EnergyClass {
  const range = DPE_RANGES.find(range => value >= range.min && value <= range.max);
  return range?.class || 'G';
}

export function calculateGESClass(value: number): EnergyClass {
  const range = GES_RANGES.find(range => value >= range.min && value <= range.max);
  return range?.class || 'G';
}