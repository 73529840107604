import React, { useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { LogIn, Mail, Key } from 'lucide-react';
import { useAuthStore } from '../../store/authStore';
import { AuthLayout } from '../../components/auth/AuthLayout';
import { FormInput } from '../../components/auth/FormInput';
import { SubmitButton } from '../../components/auth/SubmitButton';
import { signInSchema, type SignInFormData } from '../../lib/auth/validation';
import { getDefaultRoute } from '../../utils/routeUtils';

export function SignInPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn, loading, user, initialized } = useAuthStore();
  const [error, setError] = React.useState('');
  const [formData, setFormData] = React.useState<SignInFormData>({
    email: '',
    password: ''
  });

  useEffect(() => {
    if (initialized && user) {
      const defaultRoute = getDefaultRoute(user);
      navigate(defaultRoute, { replace: true });
    }
  }, [initialized, user, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      const validatedData = signInSchema.parse(formData);
      const user = await signIn(validatedData.email, validatedData.password);
      const from = (location.state as any)?.from?.pathname || getDefaultRoute(user);
      navigate(from, { replace: true });
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Une erreur est survenue lors de la connexion');
      }
    }
  };

  return (
    <AuthLayout 
      title="ImmoPunch"
      subtitle="L'indispensable pour vos visites et vos ventes !"
    >
      <form className="space-y-6" onSubmit={handleSubmit}>
        {error && (
          <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}

        <FormInput
          id="email"
          type="email"
          label="Email"
          icon={Mail}
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          placeholder="votre@email.com"
          required
        />

        <FormInput
          id="password"
          type="password"
          label="Mot de passe"
          icon={Key}
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          placeholder="••••••••"
          required
        />

        <SubmitButton loading={loading} icon={LogIn}>
          Se connecter
        </SubmitButton>

        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">
              Vous n'avez pas encore de compte ?
            </span>
          </div>
        </div>

        <Link
          to="/signup"
          className="w-full flex justify-center items-center py-3 px-4 border border-blue-300 rounded-lg shadow-sm text-sm font-medium text-blue-600 bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Créer un compte
        </Link>
      </form>
    </AuthLayout>
  );
}