import { User } from '../types/user';

export function getDefaultRoute(user: User | null): string {
  if (!user) return '/login';
  return user.role === 'admin' ? '/admin' : '/';
}

export function canAccessRoute(user: User | null, requiredRole?: string): boolean {
  if (!user) return false;
  if (!requiredRole) return true;
  return user.role === requiredRole;
}