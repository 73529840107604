import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClientStore } from '../store/clientStore';
import { usePropertyStore } from '../store/propertyStore';
import { useAuthStore } from '../store/authStore';
import { PropertyShareModal } from '../components/PropertyShareModal';
import { ClientEditModal } from '../components/ClientEditModal';
import { UserPlus, Mail, Phone, Send, Pencil, Trash2 } from 'lucide-react';
import { Client } from '../types/client';
import { Property } from '../types/property';
import { StarRating } from '../components/ui/StarRating';
import { LoadingSpinner } from '../components/LoadingSpinner';

export function ClientsPage() {
  const { user } = useAuthStore();
  const { clients, loading, error, fetchClients, updateClient, deleteClient } = useClientStore();
  const properties = usePropertyStore((state) => state.properties);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
  const [editingClient, setEditingClient] = useState<Client | null>(null);
  const [deletingId, setDeletingId] = useState<string | null>(null);

  useEffect(() => {
    if (user?.id) {
      fetchClients(user.id);
    }
  }, [user?.id, fetchClients]);

  const handleShare = (client: Client) => {
    setSelectedClient(client);
    if (properties.length === 1) {
      setSelectedProperty(properties[0]);
    }
  };

  const handleEdit = (client: Client) => {
    setEditingClient(client);
  };

  const handleSaveEdit = async (updatedClient: Client) => {
    await updateClient(updatedClient.id, updatedClient);
    setEditingClient(null);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce client ?')) {
      setDeletingId(id);
      try {
        await deleteClient(id);
      } catch (error) {
        console.error('Error deleting client:', error);
      } finally {
        setDeletingId(null);
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
        {error}
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Clients</h1>
        <Link
          to="/client/new"
          className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 inline-flex items-center"
        >
          <UserPlus className="w-4 h-4 mr-2" />
          Nouveau client
        </Link>
      </div>

      <div className="grid gap-6">
        {clients.map((client) => (
          <div key={client.id} className="bg-white rounded-lg shadow-md p-6">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-xl font-semibold">
                  {client.firstName} {client.lastName}
                </h3>
                <div className="mt-2 space-y-1">
                  <div className="flex items-center text-gray-600">
                    <Mail className="w-4 h-4 mr-2" />
                    <a href={`mailto:${client.email}`} className="hover:text-blue-600">
                      {client.email}
                    </a>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <Phone className="w-4 h-4 mr-2" />
                    <a href={`tel:${client.phone}`} className="hover:text-blue-600">
                      {client.phone}
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => handleEdit(client)}
                  className="flex items-center gap-2 px-3 py-1 text-gray-600 hover:bg-gray-50 rounded-md"
                >
                  <Pencil className="w-4 h-4" />
                  Modifier
                </button>
                <button
                  onClick={() => handleShare(client)}
                  className="flex items-center gap-2 px-3 py-1 text-blue-600 hover:bg-blue-50 rounded-md"
                >
                  <Send className="w-4 h-4" />
                  Envoyer une fiche
                </button>
                <button
                  onClick={() => handleDelete(client.id)}
                  disabled={deletingId === client.id}
                  className="flex items-center gap-2 px-3 py-1 text-red-600 hover:bg-red-50 rounded-md"
                >
                  {deletingId === client.id ? (
                    <LoadingSpinner size="sm" />
                  ) : (
                    <Trash2 className="w-4 h-4" />
                  )}
                  Supprimer
                </button>
                <div className="ml-2">
                  <StarRating value={client.rating} onChange={() => {}} />
                </div>
              </div>
            </div>

            <div className="mt-4 border-t pt-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h4 className="text-sm font-medium text-gray-500">Budget</h4>
                  <p className="mt-1">
                    {client.budget.min.toLocaleString()} € - {client.budget.max.toLocaleString()} €
                  </p>
                </div>
                <div>
                  <h4 className="text-sm font-medium text-gray-500">Surface souhaitée</h4>
                  <p className="mt-1">
                    {client.preferences.minSurface} - {client.preferences.maxSurface} m²
                  </p>
                </div>
              </div>
            </div>

            {client.notes && (
              <div className="mt-4 text-gray-600">
                <p className="text-sm">{client.notes}</p>
              </div>
            )}
          </div>
        ))}
      </div>

      {selectedClient && selectedProperty && (
        <PropertyShareModal
          client={selectedClient}
          property={selectedProperty}
          isOpen={true}
          onClose={() => {
            setSelectedClient(null);
            setSelectedProperty(null);
          }}
        />
      )}

      {editingClient && (
        <ClientEditModal
          client={editingClient}
          isOpen={true}
          onClose={() => setEditingClient(null)}
          onSave={handleSaveEdit}
        />
      )}

      {selectedClient && !selectedProperty && properties.length > 0 && (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl">
            <div className="p-4 border-b">
              <h2 className="text-lg font-semibold">Sélectionner un bien</h2>
            </div>
            <div className="p-4 max-h-[60vh] overflow-y-auto">
              <div className="grid gap-4">
                {properties.map((property) => (
                  <button
                    key={property.id}
                    onClick={() => setSelectedProperty(property)}
                    className="flex items-start gap-4 p-4 hover:bg-gray-50 rounded-lg text-left w-full"
                  >
                    <img
                      src={property.images[0] || 'https://via.placeholder.com/150'}
                      alt={property.title}
                      className="w-24 h-24 object-cover rounded-md"
                    />
                    <div>
                      <h3 className="font-medium">{property.title}</h3>
                      <p className="text-sm text-gray-600">
                        {property.address.city}, {property.address.postalCode}
                      </p>
                      <p className="text-sm font-medium mt-1">
                        {property.price.toLocaleString()} €
                      </p>
                    </div>
                  </button>
                ))}
              </div>
            </div>
            <div className="flex justify-end gap-3 p-4 border-t">
              <button
                onClick={() => setSelectedClient(null)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}