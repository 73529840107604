import React from 'react';
import { Wifi, WifiOff } from 'lucide-react';
import { getNetworkStatus } from '../lib/firebase';

export function NetworkStatus() {
  const [isOnline, setIsOnline] = React.useState(getNetworkStatus());

  React.useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (isOnline) return null;

  return (
    <div className="fixed bottom-4 right-4 bg-yellow-50 border border-yellow-200 rounded-lg shadow-lg p-4 flex items-center gap-2">
      <WifiOff className="w-5 h-5 text-yellow-600" />
      <span className="text-sm text-yellow-800">
        Mode hors ligne - Les modifications seront synchronisées lorsque la connexion sera rétablie
      </span>
    </div>
  );
}