import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserPlus, Mail, Key, User } from 'lucide-react';
import { useAuthStore } from '../../store/authStore';
import { AuthLayout } from '../../components/auth/AuthLayout';
import { FormInput } from '../../components/auth/FormInput';
import { SubmitButton } from '../../components/auth/SubmitButton';
import { signUpSchema, type SignUpFormData } from '../../lib/auth/validation';
import { Toast } from '../../components/ui/Toast';
import { getDefaultRoute } from '../../utils/routeUtils';

export function SignUpPage() {
  const navigate = useNavigate();
  const { signUp, loading } = useAuthStore();
  const [error, setError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [formData, setFormData] = useState<SignUpFormData>({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setShowSuccess(false);

    try {
      const validatedData = signUpSchema.parse(formData);
      const user = await signUp(validatedData.email, validatedData.password, {
        firstName: validatedData.firstName,
        lastName: validatedData.lastName,
        role: 'agency',
        isActive: true,
      });
      
      setShowSuccess(true);
      
      // Redirection vers le tableau de bord approprié après un court délai
      setTimeout(() => {
        const defaultRoute = getDefaultRoute(user);
        navigate(defaultRoute, { replace: true });
      }, 1500);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("Une erreur s'est produite lors de l'inscription");
      }
    }
  };

  return (
    <AuthLayout title="Inscription">
      <form className="space-y-6" onSubmit={handleSubmit}>
        {error && (
          <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}

        {showSuccess && (
          <Toast 
            type="success"
            message="Inscription réussie ! Redirection vers votre tableau de bord..."
            duration={1500}
          />
        )}

        <div className="grid grid-cols-2 gap-4">
          <FormInput
            id="firstName"
            type="text"
            label="Prénom"
            icon={User}
            value={formData.firstName}
            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
            required
            autoComplete="given-name"
          />

          <FormInput
            id="lastName"
            type="text"
            label="Nom"
            icon={User}
            value={formData.lastName}
            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
            required
            autoComplete="family-name"
          />
        </div>

        <FormInput
          id="email"
          type="email"
          label="Email professionnel"
          icon={Mail}
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          required
          autoComplete="email"
        />

        <FormInput
          id="password"
          type="password"
          label="Mot de passe"
          icon={Key}
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          required
          autoComplete="new-password"
        />

        <FormInput
          id="confirmPassword"
          type="password"
          label="Confirmer le mot de passe"
          icon={Key}
          value={formData.confirmPassword}
          onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
          required
          autoComplete="new-password"
        />

        <SubmitButton loading={loading} icon={UserPlus}>
          Créer mon compte
        </SubmitButton>
      </form>
    </AuthLayout>
  );
}