import { collection, getDocs, doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './firebase';
import { User } from '../types/user';
import { AdminSettings } from '../types/admin';

const SETTINGS_DOC_ID = 'visit-form-settings';

export async function getUsers(): Promise<User[]> {
  const querySnapshot = await getDocs(collection(db, 'users'));
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as User));
}

export async function updateUserStatus(userId: string, isActive: boolean): Promise<void> {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    isActive,
    updatedAt: new Date()
  });
}

export async function getAdminSettings(): Promise<AdminSettings> {
  const docRef = doc(db, 'settings', SETTINGS_DOC_ID);
  const docSnap = await getDoc(docRef);
  
  if (!docSnap.exists()) {
    const defaultSettings: AdminSettings = {
      visitFormClause: "Je reconnais avoir visité le bien ci-dessus désigné et m'engage à ne pas traiter directement ou indirectement avec le propriétaire sans l'intermédiaire de l'agence.",
      updatedAt: new Date(),
    };
    await setDoc(docRef, defaultSettings);
    return defaultSettings;
  }
  
  return docSnap.data() as AdminSettings;
}

export async function updateVisitFormClause(clause: string): Promise<void> {
  const settingsRef = doc(db, 'settings', SETTINGS_DOC_ID);
  await updateDoc(settingsRef, {
    visitFormClause: clause,
    updatedAt: new Date(),
  });
}