import { collection, getDocs, query, where, deleteDoc, writeBatch } from 'firebase/firestore';
import { db } from './firebase';

export async function removeDuplicateClients(agencyId: string) {
  const clientsRef = collection(db, 'clients');
  const q = query(clientsRef, where('agencyId', '==', agencyId));
  const snapshot = await getDocs(q);

  const emailMap = new Map();
  const duplicates = [];

  // Identify duplicates
  snapshot.docs.forEach(doc => {
    const data = doc.data();
    const email = data.email.toLowerCase();
    
    if (emailMap.has(email)) {
      // Keep the most recently updated document
      const existing = emailMap.get(email);
      const currentUpdated = data.updatedAt?.toDate() || new Date(0);
      const existingUpdated = existing.data.updatedAt?.toDate() || new Date(0);
      
      if (currentUpdated > existingUpdated) {
        duplicates.push(existing.id);
        emailMap.set(email, { id: doc.id, data });
      } else {
        duplicates.push(doc.id);
      }
    } else {
      emailMap.set(email, { id: doc.id, data });
    }
  });

  // Delete duplicates in batches
  const batch = writeBatch(db);
  const batchSize = 500;
  
  for (let i = 0; i < duplicates.length; i += batchSize) {
    const chunk = duplicates.slice(i, i + batchSize);
    for (const docId of chunk) {
      const docRef = clientsRef.doc(docId);
      batch.delete(docRef);
    }
    await batch.commit();
  }

  return duplicates.length;
}

export async function removeDuplicateProperties(userId: string) {
  const propertiesRef = collection(db, 'properties');
  const q = query(propertiesRef, where('userId', '==', userId));
  const snapshot = await getDocs(q);

  const referenceMap = new Map();
  const duplicates = [];

  // Identify duplicates by reference
  snapshot.docs.forEach(doc => {
    const data = doc.data();
    const reference = data.reference;
    
    if (referenceMap.has(reference)) {
      // Keep the most recently updated document
      const existing = referenceMap.get(reference);
      const currentUpdated = data.updatedAt?.toDate() || new Date(0);
      const existingUpdated = existing.data.updatedAt?.toDate() || new Date(0);
      
      if (currentUpdated > existingUpdated) {
        duplicates.push(existing.id);
        referenceMap.set(reference, { id: doc.id, data });
      } else {
        duplicates.push(doc.id);
      }
    } else {
      referenceMap.set(reference, { id: doc.id, data });
    }
  });

  // Delete duplicates in batches
  const batch = writeBatch(db);
  const batchSize = 500;
  
  for (let i = 0; i < duplicates.length; i += batchSize) {
    const chunk = duplicates.slice(i, i + batchSize);
    for (const docId of chunk) {
      const docRef = propertiesRef.doc(docId);
      batch.delete(docRef);
    }
    await batch.commit();
  }

  return duplicates.length;
}