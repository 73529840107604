import React from 'react';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { Agency } from '../../types/agent';
import { formatCurrency } from '../../utils/formatters';

const styles = StyleSheet.create({
  header: {
    marginBottom: 30,
    borderBottom: '1 solid #000',
    paddingBottom: 20,
  },
  agencyHeader: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
    width: '100%',
  },
  agencyLogo: {
    width: 180,
    height: 80,
    objectFit: 'contain',
    marginBottom: 15,
    alignSelf: 'center',
  },
  agencyInfo: {
    width: '100%',
    textAlign: 'center',
    fontSize: 11,
    color: '#1f2937',
    lineHeight: 1.4,
  },
  agencyName: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
    color: '#111827',
    textAlign: 'center',
  },
  textLine: {
    width: '100%',
    textAlign: 'center',
    marginBottom: 2,
  },
});

interface AgencyHeaderProps {
  agency: Agency;
}

export function AgencyHeader({ agency }: AgencyHeaderProps) {
  return (
    <View style={styles.header}>
      <View style={styles.agencyHeader}>
        {agency.logo && (
          <Image src={agency.logo} style={styles.agencyLogo} />
        )}
        <View style={styles.agencyInfo}>
          <Text style={styles.agencyName}>{agency.name}</Text>
          <Text style={styles.textLine}>{agency.address.street}</Text>
          <Text style={styles.textLine}>{agency.address.postalCode} {agency.address.city}</Text>
          <Text style={styles.textLine}>Tél : {agency.phone} - Email : {agency.email}</Text>
          <Text style={styles.textLine}>SIRET : {agency.siret} - Carte Transaction : {agency.carteTransaction}</Text>
          <Text style={styles.textLine}>
            {agency.garantie?.company && agency.garantie?.amount > 0
              ? `Garantie financière : ${agency.garantie.company} - ${formatCurrency(agency.garantie.amount)}`
              : "S'est engagé à ne recevoir aucun fonds"}
          </Text>
          <Text style={styles.textLine}>RC Pro : {agency.rcPro.company} - {agency.rcPro.contractNumber}</Text>
        </View>
      </View>
    </View>
  );
}