import { create } from 'zustand';
import { SMTPConfig, TestEmailData } from '../types/smtp';
import { getSMTPConfig, updateSMTPConfig, sendTestEmail } from '../lib/smtp';

interface SMTPStore {
  config: SMTPConfig | null;
  loading: boolean;
  error: string | null;
  fetchConfig: () => Promise<void>;
  updateConfig: (config: Partial<SMTPConfig>) => Promise<void>;
  sendTest: (data: TestEmailData) => Promise<void>;
}

export const useSMTPStore = create<SMTPStore>((set) => ({
  config: null,
  loading: false,
  error: null,

  fetchConfig: async () => {
    set({ loading: true, error: null });
    try {
      const config = await getSMTPConfig();
      set({ config, loading: false });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'An error occurred';
      set({ error: message, loading: false });
    }
  },

  updateConfig: async (config) => {
    set({ loading: true, error: null });
    try {
      await updateSMTPConfig(config);
      const updatedConfig = await getSMTPConfig();
      set({ config: updatedConfig, loading: false });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'An error occurred';
      set({ error: message, loading: false });
    }
  },

  sendTest: async (data) => {
    set({ loading: true, error: null });
    try {
      await sendTestEmail(data);
      set({ loading: false });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'An error occurred';
      set({ error: message, loading: false });
    }
  },
}));