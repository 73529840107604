import React, { useState } from 'react';
import { Property, Room } from '../../types/property';
import { Building2, Euro, Maximize2, MapPin } from 'lucide-react';
import { ReferenceInput } from './ReferenceInput';
import { PropertyTypeSelect } from './PropertyTypeSelect';
import { TransactionTypeSelect } from './TransactionTypeSelect';
import { ExclusivityToggle } from './ExclusivityToggle';
import { DPEInput } from './DPEInput';
import { EnergyCosts } from './EnergyCosts';
import { ImageUpload } from './ImageUpload';
import { AgentSelect } from './AgentSelect';
import { PropertyCharacteristics } from './PropertyCharacteristics';
import { RoomDetails } from './RoomDetails';
import { useAgentStore } from '../../store/agentStore';
import { useAuthStore } from '../../store/authStore';
import { LoadingSpinner } from '../LoadingSpinner';

interface PropertyFormProps {
  onSubmit: (property: Property) => Promise<void>;
  initialData?: Property;
}

export function PropertyForm({ onSubmit, initialData }: PropertyFormProps) {
  const { user } = useAuthStore();
  const { agents, loading: agentsLoading, fetchAgents } = useAgentStore();
  const [isSubmitting, setIsSubmitting] = useState(false);

  React.useEffect(() => {
    if (user?.id) {
      fetchAgents(user.id);
    }
  }, [user?.id, fetchAgents]);

  const [formData, setFormData] = useState<Partial<Property>>(() => {
    const defaultData = {
      reference: '',
      transactionType: 'sale',
      propertyType: 'house',
      title: '',
      description: '',
      price: 0,
      livingSpace: 0,
      landArea: 0,
      rooms: [] as Room[],
      bedrooms: 0,
      address: {
        street: '',
        city: '',
        postalCode: '',
        country: 'France',
      },
      energy: {
        class: 'D',
        value: 0,
      },
      ges: {
        class: 'D',
        value: 0,
      },
      energyCosts: {
        minCost: 0,
        maxCost: 0,
      },
      agencyFees: {
        included: true,
        percentage: 0,
        amount: 0,
      },
      isExclusive: false,
      features: [],
      images: [],
      agentId: '',
    };

    if (initialData) {
      return {
        ...defaultData,
        ...initialData,
        rooms: Array.isArray(initialData.rooms) ? [...initialData.rooms] : [],
        address: { ...defaultData.address, ...initialData.address },
        energy: { ...defaultData.energy, ...initialData.energy },
        ges: { ...defaultData.ges, ...initialData.ges },
        energyCosts: { ...defaultData.energyCosts, ...initialData.energyCosts },
        agencyFees: { ...defaultData.agencyFees, ...initialData.agencyFees },
      };
    }

    return defaultData;
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await onSubmit({
        ...formData,
        rooms: Array.isArray(formData.rooms) ? formData.rooms : [],
      } as Property);
    } catch (error) {
      console.error('Error submitting property:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFieldChange = (field: string, value: any) => {
    setFormData(prev => {
      if (field === 'rooms') {
        return {
          ...prev,
          rooms: Array.isArray(value) ? [...value] : [],
        };
      }
      return { ...prev, [field]: value };
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      {/* Main Information */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-lg font-semibold mb-4 flex items-center">
          <Building2 className="w-5 h-5 mr-2" />
          Informations principales
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <ReferenceInput
            value={formData.reference || ''}
            onChange={(value) => handleFieldChange('reference', value)}
          />
          
          <ExclusivityToggle
            value={formData.isExclusive || false}
            onChange={(value) => handleFieldChange('isExclusive', value)}
          />
          
          <TransactionTypeSelect
            value={formData.transactionType || 'sale'}
            onChange={(value) => handleFieldChange('transactionType', value)}
          />
          
          <PropertyTypeSelect
            value={formData.propertyType || 'house'}
            onChange={(value) => handleFieldChange('propertyType', value)}
          />
        </div>
      </div>

      {/* Characteristics */}
      <PropertyCharacteristics
        title={formData.title || ''}
        description={formData.description || ''}
        price={formData.price || 0}
        livingSpace={formData.livingSpace || 0}
        landArea={formData.landArea || 0}
        rooms={formData.rooms || []}
        bedrooms={formData.bedrooms || 0}
        agencyFees={formData.agencyFees || { included: true, percentage: 0, amount: 0 }}
        onChange={handleFieldChange}
      />

      {/* Room Details */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <RoomDetails
          rooms={Array.isArray(formData.rooms) ? formData.rooms : []}
          onChange={(rooms) => handleFieldChange('rooms', rooms)}
        />
      </div>

      {/* Location */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-lg font-semibold mb-4 flex items-center">
          <MapPin className="w-5 h-5 mr-2" />
          Localisation
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700">Adresse</label>
            <input
              type="text"
              value={formData.address?.street || ''}
              onChange={(e) => handleFieldChange('address', {
                ...formData.address,
                street: e.target.value
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Code postal</label>
            <input
              type="text"
              value={formData.address?.postalCode || ''}
              onChange={(e) => handleFieldChange('address', {
                ...formData.address,
                postalCode: e.target.value
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Ville</label>
            <input
              type="text"
              value={formData.address?.city || ''}
              onChange={(e) => handleFieldChange('address', {
                ...formData.address,
                city: e.target.value
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
        </div>
      </div>

      {/* Energy Information */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-lg font-semibold mb-4">Diagnostics énergétiques</h2>
        
        <div className="space-y-6">
          <DPEInput
            type="dpe"
            value={formData.energy?.value || 0}
            energyClass={formData.energy?.class || 'D'}
            onChange={(value, energyClass) => 
              handleFieldChange('energy', {
                value,
                class: energyClass
              })
            }
          />
          
          <DPEInput
            type="ges"
            value={formData.ges?.value || 0}
            energyClass={formData.ges?.class || 'D'}
            onChange={(value, energyClass) => 
              handleFieldChange('ges', {
                value,
                class: energyClass
              })
            }
          />

          <EnergyCosts
            value={formData.energyCosts || { minCost: 0, maxCost: 0 }}
            onChange={(costs) => handleFieldChange('energyCosts', costs)}
          />
        </div>
      </div>

      {/* Images */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <ImageUpload
          images={formData.images || []}
          onChange={(images) => handleFieldChange('images', images)}
        />
      </div>

      {/* Agent Selection */}
      {!agentsLoading && agents.length > 0 && (
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold mb-4">Mandataire en charge</h2>
          <AgentSelect
            agents={agents}
            value={formData.agentId || ''}
            onChange={(agentId) => handleFieldChange('agentId', agentId)}
          />
        </div>
      )}

      {/* Submit Button */}
      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isSubmitting}
          className="relative bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
        >
          {isSubmitting ? (
            <>
              <span className="opacity-0">
                {initialData ? 'Mettre à jour' : 'Créer le bien'}
              </span>
              <div className="absolute inset-0 flex items-center justify-center">
                <LoadingSpinner color="white" />
              </div>
            </>
          ) : (
            initialData ? 'Mettre à jour' : 'Créer le bien'
          )}
        </button>
      </div>
    </form>
  );
}