import React, { useState } from 'react';
import { Client } from '../types/client';
import { User, Euro, MapPin } from 'lucide-react';
import { StarRating } from './ui/StarRating';

interface ClientFormProps {
  onSubmit: (client: Client) => void;
  initialData?: Partial<Client>;
}

export function ClientForm({ onSubmit, initialData }: ClientFormProps) {
  const [formData, setFormData] = useState<Partial<Client>>({
    firstName: initialData?.firstName || '',
    lastName: initialData?.lastName || '',
    email: initialData?.email || '',
    phone: initialData?.phone || '',
    rating: initialData?.rating || 0,
    notes: initialData?.notes || '',
    budget: {
      min: initialData?.budget?.min || 0,
      max: initialData?.budget?.max || 0,
    },
    preferences: {
      propertyTypes: initialData?.preferences?.propertyTypes || [],
      locations: initialData?.preferences?.locations || [],
      minSurface: initialData?.preferences?.minSurface || 0,
      maxSurface: initialData?.preferences?.maxSurface || 0,
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      id: initialData?.id || Date.now().toString(),
      createdAt: initialData?.createdAt || new Date(),
      updatedAt: new Date(),
    } as Client);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-lg font-semibold mb-6 flex items-center">
          <User className="w-5 h-5 mr-2" />
          Informations personnelles
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Prénom</label>
            <input
              type="text"
              required
              value={formData.firstName}
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Nom</label>
            <input
              type="text"
              required
              value={formData.lastName}
              onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              required
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Téléphone</label>
            <input
              type="tel"
              required
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Notation du client
            </label>
            <StarRating
              value={formData.rating || 0}
              onChange={(rating) => setFormData({ ...formData, rating })}
            />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-lg font-semibold mb-6 flex items-center">
          <Euro className="w-5 h-5 mr-2" />
          Budget et préférences
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Budget minimum</label>
            <input
              type="number"
              value={formData.budget?.min}
              onChange={(e) => setFormData({
                ...formData,
                budget: { ...formData.budget!, min: Number(e.target.value) }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Budget maximum</label>
            <input
              type="number"
              value={formData.budget?.max}
              onChange={(e) => setFormData({
                ...formData,
                budget: { ...formData.budget!, max: Number(e.target.value) }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Surface minimum (m²)</label>
            <input
              type="number"
              value={formData.preferences?.minSurface}
              onChange={(e) => setFormData({
                ...formData,
                preferences: { ...formData.preferences!, minSurface: Number(e.target.value) }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Surface maximum (m²)</label>
            <input
              type="number"
              value={formData.preferences?.maxSurface}
              onChange={(e) => setFormData({
                ...formData,
                preferences: { ...formData.preferences!, maxSurface: Number(e.target.value) }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700">Notes</label>
          <textarea
            rows={4}
            value={formData.notes}
            onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {initialData ? 'Mettre à jour' : 'Créer le client'}
        </button>
      </div>
    </form>
  );
}