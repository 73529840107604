import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Property } from '../../types/property';
import { formatPrice } from '../../utils/formatters';
import { getDPERange, getGESRange } from '../../utils/dpeRanges';
import { getDPEColor, getGESColor } from '../../utils/dpeColors';

const styles = StyleSheet.create({
  section: {
    marginBottom: 15,
  },
  sectionTitle: {
    fontSize: 14,
    marginBottom: 5,
    backgroundColor: '#f3f4f6',
    padding: 5,
  },
  energyLabel: {
    flexDirection: 'row',
    marginBottom: 5,
    alignItems: 'center',
  },
  energyClass: {
    width: 30,
    height: 30,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  energyText: {
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold',
  },
  energyValue: {
    fontSize: 12,
    color: '#4B5563',
  },
  energyScale: {
    marginTop: 5,
    marginBottom: 10,
  },
  scaleRow: {
    flexDirection: 'row',
    marginBottom: 2,
  },
  scaleClass: {
    width: 20,
    padding: 2,
    marginRight: 5,
    alignItems: 'center',
  },
  scaleRange: {
    fontSize: 10,
    color: '#4B5563',
  },
  energyCost: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#F3F4F6',
    borderRadius: 4,
  },
  energyCostText: {
    fontSize: 10,
    color: '#4B5563',
    lineHeight: 1.4,
  },
});

interface EnergyInfoProps {
  property: Property;
}

export function EnergyInfo({ property }: EnergyInfoProps) {
  return (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>Diagnostics énergétiques</Text>
      
      <View>
        <Text style={{ fontSize: 12, marginBottom: 5 }}>DPE (Consommation énergétique)</Text>
        <View style={styles.energyLabel}>
          <View style={[styles.energyClass, { backgroundColor: getDPEColor(property.energy.class) }]}>
            <Text style={styles.energyText}>{property.energy.class}</Text>
          </View>
          <Text style={styles.energyValue}>{property.energy.value} kWhEP/m²/an</Text>
        </View>
        <View style={styles.energyScale}>
          {(['A', 'B', 'C', 'D', 'E', 'F', 'G'] as const).map((letter) => (
            <View key={letter} style={styles.scaleRow}>
              <View style={[styles.scaleClass, { backgroundColor: getDPEColor(letter) }]}>
                <Text style={[styles.energyText, { fontSize: 10 }]}>{letter}</Text>
              </View>
              <Text style={styles.scaleRange}>{getDPERange(letter)}</Text>
            </View>
          ))}
        </View>
      </View>

      <View style={{ marginTop: 10 }}>
        <Text style={{ fontSize: 12, marginBottom: 5 }}>GES (Émissions de CO₂)</Text>
        <View style={styles.energyLabel}>
          <View style={[styles.energyClass, { backgroundColor: getGESColor(property.ges.class) }]}>
            <Text style={styles.energyText}>{property.ges.class}</Text>
          </View>
          <Text style={styles.energyValue}>{property.ges.value} kgeqCO2/m²/an</Text>
        </View>
        <View style={styles.energyScale}>
          {(['A', 'B', 'C', 'D', 'E', 'F', 'G'] as const).map((letter) => (
            <View key={letter} style={styles.scaleRow}>
              <View style={[styles.scaleClass, { backgroundColor: getGESColor(letter) }]}>
                <Text style={[styles.energyText, { fontSize: 10 }]}>{letter}</Text>
              </View>
              <Text style={styles.scaleRange}>{getGESRange(letter)}</Text>
            </View>
          ))}
        </View>
      </View>
      
      <View style={styles.energyCost}>
        <Text style={styles.energyCostText}>
          Montant estimé des dépenses annuelles d'énergie pour un usage standard : entre {formatPrice(property.energyCosts.minCost)} € et {formatPrice(property.energyCosts.maxCost)} € par an
          {'\n'}
          Prix indexés au 1er janvier 2021 (abonnements compris)
        </Text>
      </View>
    </View>
  );
}