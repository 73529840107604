import React from 'react';
import { TransactionType } from '../../types/property';

interface TransactionTypeSelectProps {
  value: TransactionType;
  onChange: (value: TransactionType) => void;
}

export function TransactionTypeSelect({ value, onChange }: TransactionTypeSelectProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">Type de prestation</label>
      <select
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        value={value}
        onChange={(e) => onChange(e.target.value as TransactionType)}
      >
        <option value="sale">Vente</option>
        <option value="rental">Location</option>
      </select>
    </div>
  );
}