import React from 'react';
import { Agent } from '../../types/agent';
import { UserCircle } from 'lucide-react';

interface AgentSelectProps {
  agents: Agent[];
  value: string;
  onChange: (agentId: string) => void;
}

export function AgentSelect({ agents, value, onChange }: AgentSelectProps) {
  const getRoleLabel = (role: Agent['role']) => {
    const labels = {
      expert: 'Expert immobilier',
      agent: 'Agent immobilier',
      commercial: 'Commercial',
      advisor: 'Conseiller',
    };
    return labels[role];
  };

  return (
    <div className="grid grid-cols-1 gap-4">
      {agents.map((agent) => (
        <label
          key={agent.id}
          className={`relative flex items-center p-4 border rounded-lg cursor-pointer hover:bg-gray-50 ${
            value === agent.id ? 'border-blue-500 ring-2 ring-blue-200' : 'border-gray-200'
          }`}
        >
          <input
            type="radio"
            name="agent"
            value={agent.id}
            checked={value === agent.id}
            onChange={() => onChange(agent.id)}
            className="sr-only"
          />
          <div className="flex items-center gap-4">
            {agent.photo ? (
              <img
                src={agent.photo}
                alt={`${agent.firstName} ${agent.lastName}`}
                className="w-12 h-12 rounded-full object-cover"
              />
            ) : (
              <UserCircle className="w-12 h-12 text-gray-400" />
            )}
            <div>
              <div className="font-medium">
                {agent.firstName} {agent.lastName}
              </div>
              <div className="text-sm text-gray-500">{getRoleLabel(agent.role)}</div>
            </div>
          </div>
        </label>
      ))}
    </div>
  );
}