import React, { useState, KeyboardEvent } from 'react';

interface NumberInputProps {
  label: string;
  value: number;
  onChange: (value: number) => void;
  suffix?: string;
  min?: number;
  max?: number;
  placeholder?: string;
  onValidate?: () => void;
}

export function NumberInput({
  label,
  value,
  onChange,
  suffix,
  min = 0,
  max,
  placeholder,
  onValidate
}: NumberInputProps) {
  const [inputValue, setInputValue] = useState(value.toString());
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    
    const numValue = parseFloat(newValue);
    if (!isNaN(numValue)) {
      onChange(numValue);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const numValue = parseFloat(inputValue);
      if (!isNaN(numValue)) {
        if (min !== undefined && numValue < min) {
          setInputValue(min.toString());
          onChange(min);
        } else if (max !== undefined && numValue > max) {
          setInputValue(max.toString());
          onChange(max);
        } else {
          onChange(numValue);
        }
        onValidate?.();
      }
    }
  };

  const handleBlur = () => {
    const numValue = parseFloat(inputValue);
    if (isNaN(numValue)) {
      setInputValue(value.toString());
    } else {
      if (min !== undefined && numValue < min) {
        setInputValue(min.toString());
        onChange(min);
      } else if (max !== undefined && numValue > max) {
        setInputValue(max.toString());
        onChange(max);
      } else {
        setInputValue(numValue.toString());
        onChange(numValue);
      }
      onValidate?.();
    }
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          type="number"
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          min={min}
          max={max}
          placeholder={placeholder}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
        {suffix && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">{suffix}</span>
          </div>
        )}
      </div>
    </div>
  );
}