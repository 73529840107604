import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PropertyForm } from '../components/PropertyForm/PropertyForm';
import { usePropertyStore } from '../store/propertyStore';
import { useAuthStore } from '../store/authStore';
import { Property } from '../types/property';

export function NewPropertyPage() {
  const navigate = useNavigate();
  const { addProperty } = usePropertyStore();
  const { user } = useAuthStore();
  
  const handleSubmit = async (propertyData: Partial<Property>) => {
    try {
      if (!user) {
        throw new Error('User not authenticated');
      }

      // Ensure all required fields are present
      const property = {
        ...propertyData,
        agentId: user.id,
        features: propertyData.features || [],
        images: propertyData.images || [],
        address: {
          street: propertyData.address?.street || '',
          city: propertyData.address?.city || '',
          postalCode: propertyData.address?.postalCode || '',
          country: propertyData.address?.country || 'France',
        },
        energy: {
          class: propertyData.energy?.class || 'D',
          value: propertyData.energy?.value || 0,
        },
        ges: {
          class: propertyData.ges?.class || 'D',
          value: propertyData.ges?.value || 0,
        },
      } as Property;

      await addProperty(property);
      navigate('/');
    } catch (error) {
      console.error('Error creating property:', error);
      alert("Une erreur s'est produite lors de la création du bien");
    }
  };
  
  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Nouveau bien</h1>
      <PropertyForm onSubmit={handleSubmit} />
    </div>
  );
}