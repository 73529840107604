import React from 'react';
import { Agency } from '../../types/agent';
import { useAgencyStore } from '../../store/agencyStore';
import { useAuthStore } from '../../store/authStore';
import { Building2, Phone, Mail, Globe, FileText, Upload, X } from 'lucide-react';
import { LoadingSpinner } from '../LoadingSpinner';

export function AgencyProfileForm() {
  const { user } = useAuthStore();
  const { agency, loading, error, updateAgency, createAgency, fetchAgency } = useAgencyStore();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (user?.id) {
      fetchAgency(user.id);
    }
  }, [user?.id, fetchAgency]);

  const [formData, setFormData] = React.useState<Partial<Agency>>({
    name: '',
    logo: '',
    legalForm: '',
    siret: '',
    rcs: '',
    address: {
      street: '',
      city: '',
      postalCode: '',
      country: 'France',
    },
    phone: '',
    email: '',
    website: '',
    carteTransaction: '',
    garantie: {
      company: '',
      amount: 0,
    },
    rcPro: {
      company: '',
      contractNumber: '',
    },
  });

  React.useEffect(() => {
    if (agency) {
      setFormData(agency);
    }
  }, [agency]);

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, logo: reader.result as string });
      };
      reader.readAsDataURL(file);
    }
  };

  const removeLogo = () => {
    setFormData({ ...formData, logo: undefined });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.id) return;

    setIsSubmitting(true);
    try {
      if (agency) {
        await updateAgency(user.id, formData);
      } else {
        await createAgency(user.id, {
          ...formData,
          type: user.agencyType || 'traditional',
        } as Agency);
      }
      alert('Profil mis à jour avec succès');
    } catch (error) {
      console.error('Error updating agency profile:', error);
      alert("Une erreur s'est produite lors de la mise à jour");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading && !agency) {
    return (
      <div className="flex justify-center items-center h-64">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
        {error}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-lg font-semibold mb-6 flex items-center">
          <Building2 className="w-5 h-5 mr-2" />
          Identité de l'agence
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Logo de l'agence
            </label>
            <div className="flex items-start space-x-4">
              {formData.logo ? (
                <div className="relative">
                  <img
                    src={formData.logo}
                    alt="Logo de l'agence"
                    className="w-32 h-32 object-contain border rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={removeLogo}
                    className="absolute -top-2 -right-2 p-1 bg-red-100 text-red-600 rounded-full hover:bg-red-200"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
              ) : (
                <label className="flex flex-col items-center justify-center w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg hover:border-gray-400 cursor-pointer">
                  <Upload className="w-8 h-8 text-gray-400" />
                  <span className="mt-2 text-sm text-gray-500">Ajouter un logo</span>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleLogoChange}
                    className="hidden"
                  />
                </label>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Nom de l'agence</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Forme juridique</label>
            <input
              type="text"
              value={formData.legalForm}
              onChange={(e) => setFormData({ ...formData, legalForm: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">SIRET</label>
            <input
              type="text"
              value={formData.siret}
              onChange={(e) => setFormData({ ...formData, siret: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">RCS</label>
            <input
              type="text"
              value={formData.rcs}
              onChange={(e) => setFormData({ ...formData, rcs: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-lg font-semibold mb-6">Contact</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Téléphone</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Phone className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="tel"
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Site web</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Globe className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="url"
                value={formData.website}
                onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-lg font-semibold mb-6">Adresse</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700">Rue</label>
            <input
              type="text"
              value={formData.address?.street}
              onChange={(e) => setFormData({
                ...formData,
                address: { ...formData.address!, street: e.target.value }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Code postal</label>
            <input
              type="text"
              value={formData.address?.postalCode}
              onChange={(e) => setFormData({
                ...formData,
                address: { ...formData.address!, postalCode: e.target.value }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Ville</label>
            <input
              type="text"
              value={formData.address?.city}
              onChange={(e) => setFormData({
                ...formData,
                address: { ...formData.address!, city: e.target.value }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-lg font-semibold mb-6">Informations professionnelles</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Carte Transaction</label>
            <input
              type="text"
              value={formData.carteTransaction}
              onChange={(e) => setFormData({ ...formData, carteTransaction: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Garantie financière</label>
            <div className="grid grid-cols-2 gap-2">
              <input
                type="text"
                value={formData.garantie?.company}
                onChange={(e) => setFormData({
                  ...formData,
                  garantie: { ...formData.garantie!, company: e.target.value }
                })}
                placeholder="Compagnie"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              <input
                type="number"
                value={formData.garantie?.amount}
                onChange={(e) => setFormData({
                  ...formData,
                  garantie: { ...formData.garantie!, amount: Number(e.target.value) }
                })}
                placeholder="Montant"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </div>

          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700">RC Professionnelle</label>
            <div className="grid grid-cols-2 gap-2">
              <input
                type="text"
                value={formData.rcPro?.company}
                onChange={(e) => setFormData({
                  ...formData,
                  rcPro: { ...formData.rcPro!, company: e.target.value }
                })}
                placeholder="Compagnie"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              <input
                type="text"
                value={formData.rcPro?.contractNumber}
                onChange={(e) => setFormData({
                  ...formData,
                  rcPro: { ...formData.rcPro!, contractNumber: e.target.value }
                })}
                placeholder="Numéro de contrat"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isSubmitting}
          className="relative bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
        >
          {isSubmitting ? (
            <>
              <span className="opacity-0">Mettre à jour</span>
              <div className="absolute inset-0 flex items-center justify-center">
                <LoadingSpinner color="white" />
              </div>
            </>
          ) : (
            'Mettre à jour'
          )}
        </button>
      </div>
    </form>
  );
}