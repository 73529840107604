import React, { useEffect, useState } from 'react';
import { Mail, Send, Save, Lock, CheckCircle, XCircle } from 'lucide-react';
import { useSMTPStore } from '../../store/smtpStore';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { TestEmailData } from '../../types/smtp';
import { formatDistanceToNow } from '../../utils/formatters';

export function SMTPConfigPage() {
  const { config, loading, error, fetchConfig, updateConfig, sendTest } = useSMTPStore();
  const [showTestEmail, setShowTestEmail] = useState(false);
  const [testData, setTestData] = useState<TestEmailData>({
    to: '',
    subject: 'Test Email from ImmoPunch',
    body: 'This is a test email from your ImmoPunch SMTP configuration.',
  });

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const data = {
      host: formData.get('host') as string,
      port: Number(formData.get('port')),
      secure: Boolean(formData.get('secure')),
      username: formData.get('username') as string,
      password: formData.get('password') as string,
      fromName: formData.get('fromName') as string,
      fromEmail: formData.get('fromEmail') as string,
      enabled: Boolean(formData.get('enabled')),
    };
    await updateConfig(data);
  };

  const handleTestEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    await sendTest(testData);
    setShowTestEmail(false);
  };

  const renderTestStatus = () => {
    if (!config?.lastTestStatus) return null;

    const { success, message, testedAt } = config.lastTestStatus;
    const timeAgo = formatDistanceToNow(testedAt);

    return (
      <div className={`flex items-center gap-2 p-4 rounded-lg ${
        success ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
      }`}>
        {success ? (
          <CheckCircle className="w-5 h-5 text-green-500" />
        ) : (
          <XCircle className="w-5 h-5 text-red-500" />
        )}
        <div>
          <p className="font-medium">
            {success ? 'Configuration SMTP valide' : 'Échec de la configuration SMTP'}
          </p>
          <p className="text-sm opacity-75">
            {message} • Testé il y a {timeAgo}
          </p>
        </div>
      </div>
    );
  };

  if (loading && !config) {
    return (
      <div className="flex justify-center items-center h-64">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold flex items-center gap-2">
          <Mail className="w-6 h-6 text-blue-600" />
          Configuration SMTP
        </h2>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
          {error}
        </div>
      )}

      {renderTestStatus()}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Serveur SMTP</label>
              <input
                type="text"
                name="host"
                defaultValue={config?.host}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="smtp.example.com"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Port</label>
              <input
                type="number"
                name="port"
                defaultValue={config?.port || 587}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Nom d'utilisateur</label>
              <input
                type="text"
                name="username"
                defaultValue={config?.username}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Mot de passe</label>
              <input
                type="password"
                name="password"
                defaultValue={config?.password}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Nom expéditeur</label>
              <input
                type="text"
                name="fromName"
                defaultValue={config?.fromName}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Email expéditeur</label>
              <input
                type="email"
                name="fromEmail"
                defaultValue={config?.fromEmail}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div className="md:col-span-2">
              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  name="secure"
                  id="secure"
                  defaultChecked={config?.secure}
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <label htmlFor="secure" className="text-sm font-medium text-gray-700">
                  Utiliser TLS/SSL
                </label>
              </div>
            </div>

            <div className="md:col-span-2">
              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  name="enabled"
                  id="enabled"
                  defaultChecked={config?.enabled}
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <label htmlFor="enabled" className="text-sm font-medium text-gray-700">
                  Activer l'envoi d'emails
                </label>
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-end gap-4">
            <button
              type="button"
              onClick={() => setShowTestEmail(true)}
              disabled={loading || !config?.enabled}
              className="px-4 py-2 text-sm font-medium text-blue-600 bg-blue-50 rounded-md hover:bg-blue-100 disabled:opacity-50"
            >
              <Send className="w-4 h-4 inline-block mr-2" />
              Envoyer un email test
            </button>
            
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50"
            >
              {loading ? (
                <>
                  <LoadingSpinner size="sm" color="white" />
                  <span className="ml-2">Enregistrement...</span>
                </>
              ) : (
                <>
                  <Save className="w-4 h-4 inline-block mr-2" />
                  Enregistrer
                </>
              )}
            </button>
          </div>
        </div>
      </form>

      {showTestEmail && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
            <div className="flex items-center justify-between p-4 border-b">
              <h3 className="text-lg font-medium">Envoyer un email test</h3>
              <button
                onClick={() => setShowTestEmail(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Close</span>
                <Lock className="w-5 h-5" />
              </button>
            </div>

            <form onSubmit={handleTestEmail} className="p-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Destinataire
                </label>
                <input
                  type="email"
                  value={testData.to}
                  onChange={(e) => setTestData({ ...testData, to: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Sujet
                </label>
                <input
                  type="text"
                  value={testData.subject}
                  onChange={(e) => setTestData({ ...testData, subject: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Message
                </label>
                <textarea
                  value={testData.body}
                  onChange={(e) => setTestData({ ...testData, body: e.target.value })}
                  rows={4}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>

              <div className="flex justify-end gap-3 pt-4">
                <button
                  type="button"
                  onClick={() => setShowTestEmail(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50"
                >
                  {loading ? (
                    <>
                      <LoadingSpinner size="sm" color="white" />
                      <span className="ml-2">Envoi...</span>
                    </>
                  ) : (
                    <>
                      <Send className="w-4 h-4 inline-block mr-2" />
                      Envoyer
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}