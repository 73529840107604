import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PropertyForm } from '../components/PropertyForm/PropertyForm';
import { usePropertyStore } from '../store/propertyStore';
import { Property } from '../types/property';

export function EditPropertyPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { properties, updateProperty } = usePropertyStore();
  const property = properties.find(p => p.id === id);

  if (!property) {
    return <div>Bien non trouvé</div>;
  }

  const handleSubmit = async (updatedProperty: Property) => {
    try {
      await updateProperty(id!, {
        ...updatedProperty,
        rooms: Array.isArray(updatedProperty.rooms) ? updatedProperty.rooms : [],
      });
      navigate('/');
    } catch (error) {
      console.error('Error updating property:', error);
    }
  };

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Modifier le bien</h1>
      <PropertyForm
        initialData={property}
        onSubmit={handleSubmit}
      />
    </div>
  );
}