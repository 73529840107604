import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs, query, where, Timestamp } from 'firebase/firestore';
import { db } from './firebase';
import { Client } from '../types/client';

const CLIENTS_COLLECTION = 'clients';

export async function getClientsByAgency(agencyId: string): Promise<Client[]> {
  const q = query(collection(db, CLIENTS_COLLECTION), where('agencyId', '==', agencyId));
  const snapshot = await getDocs(q);
  
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    createdAt: doc.data().createdAt?.toDate(),
    updatedAt: doc.data().updatedAt?.toDate(),
  })) as Client[];
}

export async function addClient(client: Omit<Client, 'id'>): Promise<string> {
  const clientData = {
    ...client,
    createdAt: Timestamp.fromDate(new Date()),
    updatedAt: Timestamp.fromDate(new Date()),
  };
  
  const docRef = await addDoc(collection(db, CLIENTS_COLLECTION), clientData);
  return docRef.id;
}

export async function updateClient(id: string, client: Partial<Client>): Promise<void> {
  const clientRef = doc(db, CLIENTS_COLLECTION, id);
  await updateDoc(clientRef, {
    ...client,
    updatedAt: Timestamp.fromDate(new Date()),
  });
}

export async function deleteClient(id: string): Promise<void> {
  const clientRef = doc(db, CLIENTS_COLLECTION, id);
  await deleteDoc(clientRef);
}