import { collection, doc, getDoc, setDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from './firebase';
import { Agency } from '../types/agent';

const AGENCIES_COLLECTION = 'agencies';

export async function getAgency(id: string): Promise<Agency | null> {
  const docRef = doc(db, AGENCIES_COLLECTION, id);
  const docSnap = await getDoc(docRef);
  
  if (!docSnap.exists()) return null;
  
  return {
    id: docSnap.id,
    ...docSnap.data(),
    createdAt: docSnap.data().createdAt?.toDate(),
    updatedAt: docSnap.data().updatedAt?.toDate(),
  } as Agency;
}

export async function updateAgency(id: string, data: Partial<Agency>): Promise<void> {
  const agencyRef = doc(db, AGENCIES_COLLECTION, id);
  await updateDoc(agencyRef, {
    ...data,
    updatedAt: Timestamp.fromDate(new Date()),
  });
}

export async function createAgency(id: string, data: Omit<Agency, 'id' | 'createdAt' | 'updatedAt'>): Promise<void> {
  const agencyRef = doc(db, AGENCIES_COLLECTION, id);
  await setDoc(agencyRef, {
    ...data,
    createdAt: Timestamp.fromDate(new Date()),
    updatedAt: Timestamp.fromDate(new Date()),
  });
}