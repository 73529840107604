import React, { useState, useEffect } from 'react';
import { AgencyFees } from '../../types/property';
import { NumberInput } from './NumberInput';
import { formatPrice } from '../../utils/formatters';
import { Check } from 'lucide-react';

interface AgencyFeesInputProps {
  value: AgencyFees;
  onChange: (fees: AgencyFees) => void;
  propertyPrice: number;
}

export function AgencyFeesInput({ value, onChange, propertyPrice }: AgencyFeesInputProps) {
  // Initialize with default values if none provided
  const defaultValue: AgencyFees = {
    included: true,
    percentage: value?.percentage || 0,
    amount: value?.amount || 0,
  };

  // Ensure we have a valid value object
  const fees = value || defaultValue;
  const [isPercentageValidated, setIsPercentageValidated] = useState(fees.percentage > 0);
  const [tempPercentage, setTempPercentage] = useState(fees.percentage);

  // Calculate amounts when percentage changes or on initial load
  useEffect(() => {
    if (isPercentageValidated && fees.percentage > 0) {
      const netPrice = propertyPrice / (1 + fees.percentage / 100);
      const amount = propertyPrice - netPrice;
      
      if (Math.abs(amount - fees.amount) > 1) { // Use threshold to avoid floating point issues
        onChange({
          included: true,
          percentage: fees.percentage,
          amount: Math.round(amount)
        });
      }
    }
  }, [propertyPrice, fees.percentage, isPercentageValidated]);

  // Persist validated percentage when component remounts
  useEffect(() => {
    if (value?.percentage > 0) {
      setTempPercentage(value.percentage);
      setIsPercentageValidated(true);
    }
  }, [value?.percentage]);

  const handlePercentageChange = (newPercentage: number) => {
    setTempPercentage(newPercentage);
  };

  const handleValidatePercentage = () => {
    if (tempPercentage > 0) {
      const netPrice = propertyPrice / (1 + tempPercentage / 100);
      const amount = propertyPrice - netPrice;
      
      onChange({
        included: true,
        percentage: tempPercentage,
        amount: Math.round(amount)
      });
      setIsPercentageValidated(true);
    }
  };

  // Calculate net seller price
  const netSellerPrice = propertyPrice - fees.amount;

  return (
    <div className="space-y-4">
      <div className="text-sm font-medium text-gray-700 mb-4">
        Prix FAI (Frais d'Agence Inclus) : {formatPrice(propertyPrice)} €
      </div>

      <div className="space-y-4">
        <div className="flex gap-4 items-end">
          <div className="flex-1">
            <NumberInput
              label="Pourcentage des honoraires à la charge de l'acquéreur"
              value={tempPercentage}
              onChange={handlePercentageChange}
              suffix="%"
              min={0}
              max={100}
              placeholder="Saisissez le pourcentage"
              onValidate={handleValidatePercentage}
            />
          </div>
          <button
            type="button"
            onClick={handleValidatePercentage}
            className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 flex items-center gap-2"
          >
            <Check className="w-4 h-4" />
            Valider
          </button>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Montant des honoraires
            </label>
            <div className="mt-1 text-lg font-semibold text-gray-900">
              {formatPrice(fees.amount)} € TTC
            </div>
            <div className="mt-1 text-sm text-gray-500">
              {isPercentageValidated && fees.percentage > 0 
                ? `${fees.percentage}% du prix net vendeur` 
                : 'Définissez un pourcentage et cliquez sur Valider'}
            </div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Net vendeur
            </label>
            <div className="mt-1 text-lg font-semibold text-gray-900">
              {formatPrice(netSellerPrice)} €
            </div>
          </div>
        </div>

        {isPercentageValidated && fees.percentage > 0 && (
          <div className="mt-2 p-4 bg-green-50 border border-green-200 rounded-md">
            <p className="text-sm text-green-800">
              Prix de vente : {formatPrice(propertyPrice)} € FAI
              <br />
              Honoraires : {formatPrice(fees.amount)} € TTC ({fees.percentage}% du prix net vendeur)
              <br />
              Prix net vendeur : {formatPrice(netSellerPrice)} €
            </p>
          </div>
        )}
      </div>
    </div>
  );
}