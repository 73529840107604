import React from 'react';

interface EnergyCosts {
  minCost: number;
  maxCost: number;
}

interface EnergyCostsProps {
  value: EnergyCosts;
  onChange: (costs: EnergyCosts) => void;
}

export function EnergyCosts({ value, onChange }: EnergyCostsProps) {
  return (
    <div className="space-y-4">
      <label className="block text-sm font-medium text-gray-700">
        Dépenses annuelles d'énergie estimées
      </label>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm text-gray-600">Coût minimum</label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="number"
              value={value.minCost}
              onChange={(e) => onChange({ ...value, minCost: Number(e.target.value) })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="0"
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">€</span>
            </div>
          </div>
        </div>
        <div>
          <label className="block text-sm text-gray-600">Coût maximum</label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="number"
              value={value.maxCost}
              onChange={(e) => onChange({ ...value, maxCost: Number(e.target.value) })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="0"
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">€</span>
            </div>
          </div>
        </div>
      </div>
      <p className="text-sm text-gray-500">
        Prix indexés aux années 2021, 2022 et 2023 (abonnements compris)
      </p>
    </div>
  );
}