import React, { useState } from 'react';
import { Room, RoomType } from '../../types/property';
import { Plus, Trash2, Home, Check } from 'lucide-react';

interface RoomDetailsProps {
  rooms: Room[];
  onChange: (rooms: Room[]) => void;
}

export function RoomDetails({ rooms = [], onChange }: RoomDetailsProps) {
  const [currentRoom, setCurrentRoom] = useState<Room | null>(null);

  const addRoom = () => {
    setCurrentRoom({
      id: Date.now().toString(),
      name: '',
      type: 'ground_floor',
      surface: 0,
      description: '',
    });
  };

  const updateCurrentRoom = (updates: Partial<Room>) => {
    if (currentRoom) {
      setCurrentRoom({ ...currentRoom, ...updates });
    }
  };

  const validateRoom = () => {
    if (currentRoom && currentRoom.name) {
      const updatedRooms = [...rooms, currentRoom];
      onChange(updatedRooms);
      setCurrentRoom(null);
    }
  };

  const removeRoom = (id: string) => {
    const updatedRooms = rooms.filter((room) => room.id !== id);
    onChange(updatedRooms);
  };

  const getRoomTypeLabel = (type: RoomType) => {
    const labels: Record<RoomType, string> = {
      ground_floor: 'Rez de chaussée',
      first_floor: '1er étage',
      second_floor: '2ème étage',
      basement: 'Sous-sol',
      cellar: 'Cave'
    };
    return labels[type];
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium text-gray-900 flex items-center gap-2">
          <Home className="w-5 h-5" />
          Détail des pièces
        </h3>
        {!currentRoom && (
          <button
            type="button"
            onClick={addRoom}
            className="flex items-center gap-2 text-sm text-blue-600 hover:text-blue-700"
          >
            <Plus className="w-4 h-4" />
            Ajouter une pièce
          </button>
        )}
      </div>

      {/* Current Room Form */}
      {currentRoom && (
        <div className="bg-blue-50 p-4 rounded-lg space-y-4 border-2 border-blue-200">
          <div className="flex justify-between">
            <div className="flex-1 grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nom de la pièce *
                </label>
                <input
                  type="text"
                  value={currentRoom.name}
                  onChange={(e) => updateCurrentRoom({ name: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Ex: Chambre principale"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Type de pièce
                </label>
                <select
                  value={currentRoom.type}
                  onChange={(e) => updateCurrentRoom({ type: e.target.value as RoomType })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="ground_floor">Rez de chaussée</option>
                  <option value="first_floor">1er étage</option>
                  <option value="second_floor">2ème étage</option>
                  <option value="basement">Sous-sol</option>
                  <option value="cellar">Cave</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Surface (m²)
                </label>
                <input
                  type="number"
                  value={currentRoom.surface || ''}
                  onChange={(e) => updateCurrentRoom({ surface: Number(e.target.value) })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  min="0"
                  step="0.5"
                />
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              value={currentRoom.description}
              onChange={(e) => updateCurrentRoom({ description: e.target.value })}
              rows={2}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Description optionnelle de la pièce..."
            />
          </div>

          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={() => setCurrentRoom(null)}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              type="button"
              onClick={validateRoom}
              disabled={!currentRoom.name}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Check className="w-4 h-4" />
              Valider la pièce
            </button>
          </div>
        </div>
      )}

      {/* List of Added Rooms */}
      <div className="space-y-4">
        {rooms.map((room) => (
          <div
            key={room.id}
            className="bg-gray-50 p-4 rounded-lg"
          >
            <div className="flex justify-between items-start">
              <div>
                <h4 className="font-medium">{room.name}</h4>
                <div className="text-sm text-gray-500 mt-1">
                  <p>{getRoomTypeLabel(room.type)}</p>
                  {room.surface > 0 && <p>{room.surface} m²</p>}
                  {room.description && (
                    <p className="mt-1">{room.description}</p>
                  )}
                </div>
              </div>
              <button
                type="button"
                onClick={() => removeRoom(room.id)}
                className="text-red-600 hover:text-red-700"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>
          </div>
        ))}
      </div>

      {rooms.length > 0 && rooms.some(room => room.surface > 0) && (
        <div className="pt-4 border-t">
          <div className="text-sm text-gray-500">
            Surface totale des pièces : {rooms.reduce((total, room) => total + (room.surface || 0), 0)} m²
          </div>
        </div>
      )}
    </div>
  );
}