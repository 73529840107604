import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { LoadingScreen } from '../layout/LoadingScreen';
import { canAccessRoute } from '../../utils/routeUtils';

interface PrivateRouteProps {
  children: React.ReactNode;
  requiredRole?: 'admin' | 'agency' | 'agent';
}

export function PrivateRoute({ children, requiredRole }: PrivateRouteProps) {
  const { user, loading, initialized } = useAuth();
  const location = useLocation();

  if (!initialized || loading) {
    return <LoadingScreen />;
  }

  if (!canAccessRoute(user, requiredRole)) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (user && !user.isActive) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="text-center">
              <h2 className="text-xl font-semibold text-gray-900 mb-2">
                Compte suspendu
              </h2>
              <p className="text-gray-600">
                Votre compte a été suspendu. Veuillez contacter l'administrateur pour plus d'informations.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <>{children}</>;
}