import { create } from 'zustand';
import { User } from '../types/user';
import {
  signIn as firebaseSignIn,
  signUp as firebaseSignUp,
  signOut as firebaseSignOut,
  getCurrentUser,
  updateUserProfile,
} from '../lib/auth';

interface AuthStore {
  user: User | null;
  loading: boolean;
  initialized: boolean;
  error: string | null;
  signIn: (email: string, password: string) => Promise<User>;
  signUp: (email: string, password: string, userData: Partial<User>) => Promise<User>;
  signOut: () => Promise<void>;
  updateProfile: (data: Partial<User>) => Promise<void>;
  setInitialized: (value: boolean) => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  user: null,
  loading: true,
  initialized: false,
  error: null,

  setInitialized: (value: boolean) => set({ initialized: value }),

  signIn: async (email, password) => {
    set({ loading: true, error: null });
    try {
      const user = await firebaseSignIn(email, password);
      set({ user, loading: false, error: null });
      return user;
    } catch (error) {
      set({ loading: false, error: (error as Error).message, user: null });
      throw error;
    }
  },

  signUp: async (email, password, userData) => {
    set({ loading: true, error: null });
    try {
      const user = await firebaseSignUp(email, password, userData);
      set({ user, loading: false, error: null });
      return user;
    } catch (error) {
      set({ loading: false, error: (error as Error).message, user: null });
      throw error;
    }
  },

  signOut: async () => {
    set({ loading: true, error: null });
    try {
      await firebaseSignOut();
      set({ user: null, loading: false, error: null });
    } catch (error) {
      set({ loading: false, error: (error as Error).message });
      throw error;
    }
  },

  updateProfile: async (data) => {
    set({ loading: true, error: null });
    try {
      const updatedUser = await updateUserProfile(data);
      set({ user: updatedUser, loading: false, error: null });
    } catch (error) {
      set({ loading: false, error: (error as Error).message });
      throw error;
    }
  },
}));

// Initialize auth state
getCurrentUser().then((user) => {
  useAuthStore.setState({ 
    user, 
    loading: false, 
    initialized: true,
    error: null 
  });
}).catch((error) => {
  useAuthStore.setState({ 
    user: null, 
    loading: false, 
    initialized: true,
    error: error.message 
  });
});