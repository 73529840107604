import React from 'react';
import { NumberInput } from './NumberInput';
import { AgencyFeesInput } from './AgencyFeesInput';
import { Maximize2 } from 'lucide-react';
import { AgencyFees, Room } from '../../types/property';

interface PropertyCharacteristicsProps {
  title: string;
  description: string;
  price: number;
  livingSpace: number;
  landArea: number;
  rooms: Room[];
  bedrooms: number;
  agencyFees: AgencyFees;
  onChange: (field: string, value: any) => void;
}

export function PropertyCharacteristics({
  title,
  description,
  price,
  livingSpace,
  landArea,
  rooms,
  bedrooms,
  agencyFees,
  onChange,
}: PropertyCharacteristicsProps) {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-lg font-semibold mb-4 flex items-center">
        <Maximize2 className="w-5 h-5 mr-2" />
        Caractéristiques
      </h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">Titre</label>
          <input
            type="text"
            value={title}
            onChange={(e) => onChange('title', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Prix FAI</label>
          <input
            type="number"
            value={price}
            onChange={(e) => onChange('price', Number(e.target.value))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div className="md:col-span-2">
          <AgencyFeesInput
            value={agencyFees}
            onChange={(fees) => onChange('agencyFees', fees)}
            propertyPrice={price}
          />
        </div>

        <div>
          <NumberInput
            label="Surface habitable (m²)"
            value={livingSpace}
            onChange={(value) => onChange('livingSpace', value)}
            min={0}
          />
        </div>

        <div>
          <NumberInput
            label="Surface du terrain (m²)"
            value={landArea}
            onChange={(value) => onChange('landArea', value)}
            min={0}
          />
        </div>

        <div>
          <NumberInput
            label="Nombre de chambres"
            value={bedrooms}
            onChange={(value) => onChange('bedrooms', value)}
            min={0}
          />
        </div>

        <div>
          <NumberInput
            label="Nombre total de pièces"
            value={rooms.length}
            onChange={(value) => {}} // This is now controlled by the RoomDetails component
            min={0}
            disabled={true}
          />
        </div>
      </div>

      <div className="mt-6">
        <label className="block text-sm font-medium text-gray-700">Description</label>
        <textarea
          rows={4}
          value={description}
          onChange={(e) => onChange('description', e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>
    </div>
  );
}