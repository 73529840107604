import React from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import { Building2, Users, Settings, LogOut, Home, ChevronRight } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { PropertiesPage } from './PropertiesPage';
import { NewPropertyPage } from './NewPropertyPage';
import { EditPropertyPage } from './EditPropertyPage';
import { ClientsPage } from './ClientsPage';
import { NewClientPage } from './NewClientPage';
import { AgencyProfilePage } from './AgencyProfilePage';
import { NetworkStatus } from '../components/NetworkStatus';

export function UserDashboard() {
  const navigate = useNavigate();
  const { user, signOut } = useAuthStore();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-600 via-blue-400 to-purple-500 relative overflow-hidden">
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -left-4 top-1/4 w-24 h-24 bg-white/10 rounded-full blur-xl animate-float"></div>
        <div className="absolute right-10 top-1/3 w-32 h-32 bg-purple-300/20 rounded-full blur-xl animate-float-delayed"></div>
        <div className="absolute left-1/3 bottom-1/4 w-40 h-40 bg-blue-300/20 rounded-full blur-xl animate-float-slow"></div>
      </div>

      <div className="relative z-10">
        <nav className="bg-white/10 backdrop-blur-md shadow-lg">
          <div className="max-w-7xl mx-auto px-4">
            <div className="flex justify-between h-16">
              <div className="flex items-center space-x-8">
                <div className="flex-shrink-0 flex items-center">
                  <div className="w-10 h-10 bg-white rounded-lg shadow-lg flex items-center justify-center animate-bounce-gentle">
                    <Home className="h-6 w-6 text-blue-600" />
                  </div>
                  <span className="ml-3 text-xl font-bold text-white">ImmoPunch</span>
                </div>
                <NavLink to="/">
                  <Building2 className="w-5 h-5 mr-2" />
                  Biens
                </NavLink>
                <NavLink to="/clients">
                  <Users className="w-5 h-5 mr-2" />
                  Clients
                </NavLink>
                {user?.role === 'agency' && (
                  <NavLink to="/agency/profile">
                    <Settings className="w-5 h-5 mr-2" />
                    Mon agence
                  </NavLink>
                )}
              </div>
              <div className="flex items-center space-x-4">
                <div className="flex items-center text-white">
                  <div className="w-8 h-8 bg-white/20 rounded-lg flex items-center justify-center">
                    <span className="font-medium">{user?.firstName?.[0]}</span>
                  </div>
                  <span className="ml-2 font-medium">{user?.firstName}</span>
                </div>
                <button
                  onClick={handleSignOut}
                  className="flex items-center text-white hover:bg-white/10 px-4 py-2 rounded-lg transition-colors"
                >
                  <LogOut className="w-5 h-5 mr-2" />
                  Déconnexion
                </button>
              </div>
            </div>
          </div>
        </nav>

        <main className="max-w-7xl mx-auto px-4 py-8">
          <div className="bg-white/90 backdrop-blur-xl rounded-2xl shadow-xl p-6">
            <Routes>
              <Route path="/" element={<PropertiesPage />} />
              <Route path="/property/new" element={<NewPropertyPage />} />
              <Route path="/property/edit/:id" element={<EditPropertyPage />} />
              <Route path="/clients" element={<ClientsPage />} />
              <Route path="/client/new" element={<NewClientPage />} />
              <Route path="/agency/profile" element={<AgencyProfilePage />} />
            </Routes>
          </div>
        </main>
      </div>
      <NetworkStatus />
    </div>
  );
}

function NavLink({ to, children }: { to: string; children: React.ReactNode }) {
  return (
    <Link
      to={to}
      className="flex items-center text-white hover:bg-white/10 px-4 py-2 rounded-lg transition-colors"
    >
      {children}
      <ChevronRight className="w-4 h-4 ml-1 opacity-50" />
    </Link>
  );
}