import React, { useState } from 'react';
import { Property } from '../types/property';
import { Euro, MapPin, Maximize2, Home, Pencil, Trash2, ChevronLeft, ChevronRight, FileText, ClipboardList } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { usePropertyStore } from '../store/propertyStore';
import { formatCurrency, formatNumber } from '../utils/formatters';
import { PropertyPDFModal } from './PropertyPDFModal';
import { VisitFormModal } from './VisitFormModal';
import { getDPEColor, getGESColor } from '../utils/dpeColors';
import { LoadingSpinner } from './LoadingSpinner';

interface PropertyCardProps {
  property: Property;
}

export function PropertyCard({ property }: PropertyCardProps) {
  const navigate = useNavigate();
  const { deleteProperty } = usePropertyStore();
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showPDF, setShowPDF] = useState(false);
  const [showVisitForm, setShowVisitForm] = useState(false);

  const handleDelete = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce bien ?')) {
      setIsDeleting(true);
      try {
        await deleteProperty(property.id);
      } catch (error) {
        console.error('Error deleting property:', error);
        alert("Une erreur s'est produite lors de la suppression");
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="relative h-48">
        <img
          src={property.images[currentImageIndex] || 'https://images.unsplash.com/photo-1564013799919-ab600027ffc6?auto=format&fit=crop&w=800&q=80'}
          alt={property.title}
          className="w-full h-full object-cover"
        />
        {property.images.length > 1 && (
          <>
            <button
              onClick={() => setCurrentImageIndex(i => i === 0 ? property.images.length - 1 : i - 1)}
              className="absolute left-2 top-1/2 -translate-y-1/2 p-1 bg-white/80 rounded-full hover:bg-white"
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
            <button
              onClick={() => setCurrentImageIndex(i => i === property.images.length - 1 ? 0 : i + 1)}
              className="absolute right-2 top-1/2 -translate-y-1/2 p-1 bg-white/80 rounded-full hover:bg-white"
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          </>
        )}
      </div>
      
      <div className="p-4">
        <div className="flex justify-between items-start mb-2">
          <h3 className="text-xl font-semibold">{property.title}</h3>
          <div className="flex gap-2">
            <button
              onClick={() => navigate(`/property/edit/${property.id}`)}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <Pencil className="w-4 h-4 text-gray-600" />
            </button>
            <button
              onClick={handleDelete}
              disabled={isDeleting}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              {isDeleting ? (
                <LoadingSpinner size="sm" />
              ) : (
                <Trash2 className="w-4 h-4 text-red-600" />
              )}
            </button>
          </div>
        </div>
        
        <div className="flex items-center text-gray-600 mb-2">
          <MapPin className="w-4 h-4 mr-1" />
          <span>{property.address.city}, {property.address.postalCode}</span>
        </div>
        
        <div className="flex items-center justify-between text-gray-600 mb-4">
          <div className="flex items-center">
            <Euro className="w-4 h-4 mr-1" />
            <span className="font-semibold">{formatCurrency(property.price)}</span>
          </div>
          <div className="flex items-center">
            <Maximize2 className="w-4 h-4 mr-1" />
            <span>{formatNumber(property.livingSpace)} m²</span>
          </div>
        </div>

        <div className="flex gap-2 mt-4">
          <button
            onClick={() => setShowPDF(true)}
            className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100"
          >
            <FileText className="w-4 h-4" />
            Fiche du bien
          </button>
          
          <button
            onClick={() => setShowVisitForm(true)}
            className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-green-50 text-green-600 rounded-md hover:bg-green-100"
          >
            <ClipboardList className="w-4 h-4" />
            Bon de visite
          </button>
        </div>
      </div>

      {showPDF && (
        <PropertyPDFModal
          property={property}
          isOpen={true}
          onClose={() => setShowPDF(false)}
        />
      )}

      {showVisitForm && (
        <VisitFormModal
          property={property}
          isOpen={true}
          onClose={() => setShowVisitForm(false)}
        />
      )}
    </div>
  );
}