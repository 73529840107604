import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Property } from '../../types/property';
import { formatPrice, formatNumber, formatCurrency } from '../../utils/formatters';

const styles = StyleSheet.create({
  section: {
    marginBottom: 15,
  },
  sectionTitle: {
    fontSize: 14,
    marginBottom: 5,
    backgroundColor: '#f3f4f6',
    padding: 5,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  label: {
    width: 150,
    color: '#4b5563',
  },
  value: {
    flex: 1,
  },
  description: {
    marginTop: 10,
    fontSize: 12,
    lineHeight: 1.4,
  },
  priceDetails: {
    marginTop: 5,
    marginLeft: 20,
    fontSize: 11,
    color: '#4b5563',
  },
  priceRow: {
    marginBottom: 3,
  },
  roomsSection: {
    marginTop: 10,
    borderTop: '1 solid #e5e7eb',
    paddingTop: 10,
  },
  roomTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 3,
  },
  roomDetails: {
    marginLeft: 20,
    fontSize: 10,
    color: '#4b5563',
  },
  roomRow: {
    marginBottom: 2,
  },
});

interface PropertyDetailsProps {
  property: Property;
}

export function PropertyDetails({ property }: PropertyDetailsProps) {
  const getTransactionTypeLabel = (type: Property['transactionType']) => {
    return type === 'sale' ? 'Vente' : 'Location';
  };

  const getPropertyTypeLabel = (type: Property['propertyType']) => {
    const labels = {
      property: 'Propriété',
      forest: 'Forêt',
      house: 'Maison',
      land: 'Terrain',
    };
    return labels[type];
  };

  const getRoomTypeLabel = (type: string) => {
    const labels: Record<string, string> = {
      ground_floor: 'Rez de chaussée',
      first_floor: '1er étage',
      second_floor: '2ème étage',
      basement: 'Sous-sol',
      cellar: 'Cave',
    };
    return labels[type] || type;
  };

  const netSellerPrice = property.price - (property.agencyFees?.amount || 0);
  const rooms = Array.isArray(property.rooms) ? property.rooms : [];
  const totalSurface = rooms.reduce((total, room) => total + (room.surface || 0), 0);

  return (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>Caractéristiques du bien</Text>
      
      <View style={styles.row}>
        <Text style={styles.label}>Référence :</Text>
        <Text style={styles.value}>{property.reference}</Text>
      </View>
      
      <View style={styles.row}>
        <Text style={styles.label}>Type de transaction :</Text>
        <Text style={styles.value}>{getTransactionTypeLabel(property.transactionType)}</Text>
      </View>
      
      <View style={styles.row}>
        <Text style={styles.label}>Type de bien :</Text>
        <Text style={styles.value}>{getPropertyTypeLabel(property.propertyType)}</Text>
      </View>
      
      <View style={styles.row}>
        <Text style={styles.label}>Prix FAI :</Text>
        <Text style={styles.value}>{formatCurrency(property.price)}</Text>
      </View>

      <View style={styles.priceDetails}>
        <View style={styles.priceRow}>
          <Text>Honoraires d'agence : {formatCurrency(property.agencyFees?.amount || 0)} TTC ({property.agencyFees?.percentage || 0}% du prix net vendeur)</Text>
        </View>
        <View style={styles.priceRow}>
          <Text>Prix net vendeur : {formatCurrency(netSellerPrice)}</Text>
        </View>
      </View>
      
      <View style={styles.row}>
        <Text style={styles.label}>Surface habitable :</Text>
        <Text style={styles.value}>{formatNumber(property.livingSpace)} m²</Text>
      </View>
      
      {property.landArea > 0 && (
        <View style={styles.row}>
          <Text style={styles.label}>Surface terrain :</Text>
          <Text style={styles.value}>{formatNumber(property.landArea)} m²</Text>
        </View>
      )}

      {rooms.length > 0 && (
        <View style={styles.roomsSection}>
          <Text style={styles.sectionTitle}>Détail des pièces</Text>
          <Text style={styles.roomRow}>Surface totale des pièces : {formatNumber(totalSurface)} m²</Text>
          
          {rooms.map((room, index) => (
            <View key={index} style={styles.roomDetails}>
              <Text style={styles.roomTitle}>
                {room.name}
              </Text>
              <Text style={styles.roomRow}>Niveau : {getRoomTypeLabel(room.type)}</Text>
              {room.surface > 0 && (
                <Text style={styles.roomRow}>Surface : {formatNumber(room.surface)} m²</Text>
              )}
              {room.description && (
                <Text style={styles.roomRow}>Description : {room.description}</Text>
              )}
            </View>
          ))}
        </View>
      )}

      <Text style={styles.description}>{property.description}</Text>
    </View>
  );
}