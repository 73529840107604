import { create } from 'zustand';
import { Agency } from '../types/agent';
import {
  getAgency,
  updateAgency as updateAgencyInFirebase,
  createAgency as createAgencyInFirebase,
} from '../lib/agencies';

interface AgencyStore {
  agency: Agency | null;
  loading: boolean;
  error: string | null;
  fetchAgency: (id: string) => Promise<void>;
  updateAgency: (id: string, data: Partial<Agency>) => Promise<void>;
  createAgency: (id: string, data: Omit<Agency, 'id' | 'createdAt' | 'updatedAt'>) => Promise<void>;
}

export const useAgencyStore = create<AgencyStore>((set) => ({
  agency: null,
  loading: false,
  error: null,

  fetchAgency: async (id) => {
    set({ loading: true, error: null });
    try {
      const agency = await getAgency(id);
      set({ agency });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  updateAgency: async (id, data) => {
    set({ loading: true, error: null });
    try {
      await updateAgencyInFirebase(id, data);
      set((state) => ({
        agency: state.agency ? { ...state.agency, ...data } : null,
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  createAgency: async (id, data) => {
    set({ loading: true, error: null });
    try {
      await createAgencyInFirebase(id, data);
      set({ agency: { ...data, id } as Agency });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage });
      throw error;
    } finally {
      set({ loading: false });
    }
  },
}));