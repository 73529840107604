import { EnergyClass } from '../types/property';

export function getDPEColor(class_: EnergyClass): string {
  const colors: Record<EnergyClass, string> = {
    'A': '#319834',
    'B': '#33cc31',
    'C': '#cbef43',
    'D': '#fff12c',
    'E': '#fabe28',
    'F': '#ec6527',
    'G': '#ec2327'
  };
  return colors[class_];
}

export function getGESColor(class_: EnergyClass): string {
  const colors: Record<EnergyClass, string> = {
    'A': '#f2eff3',
    'B': '#dfc1f7',
    'C': '#d6aaf4',
    'D': '#cc93f4',
    'E': '#bb72f3',
    'F': '#8a50f3',
    'G': '#472a90'
  };
  return colors[class_];
}