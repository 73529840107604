import React, { useEffect } from 'react';
import { Property } from '../types/property';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { formatNumber, formatPrice } from '../utils/formatters';
import { useAgencyStore } from '../store/agencyStore';
import { useAuthStore } from '../store/authStore';
import { AgencyHeader } from './PDFGenerator/AgencyHeader';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
  },
  title: {
    fontSize: 18,
    marginTop: 20,
    marginBottom: 10,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 10,
    textAlign: 'center',
  },
  section: {
    marginBottom: 15,
  },
  sectionTitle: {
    fontSize: 14,
    marginBottom: 5,
    backgroundColor: '#f3f4f6',
    padding: 5,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  label: {
    width: 150,
    color: '#4b5563',
  },
  value: {
    flex: 1,
  },
  clause: {
    marginTop: 20,
    marginBottom: 20,
    padding: 10,
    backgroundColor: '#f3f4f6',
    fontSize: 10,
    color: '#4b5563',
    lineHeight: 1.4,
  },
  visitInfo: {
    marginTop: 30,
    borderTop: '1 solid #000',
    paddingTop: 10,
  },
  signature: {
    marginTop: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  signatureBox: {
    width: 200,
    height: 100,
    border: '1 solid #000',
    marginTop: 10,
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    fontSize: 8,
    textAlign: 'center',
    color: '#6b7280',
  },
});

interface VisitFormProps {
  property: Property;
}

export function VisitForm({ property }: VisitFormProps) {
  const { user } = useAuthStore();
  const { agency, fetchAgency } = useAgencyStore();
  const today = new Date().toLocaleDateString('fr-FR');

  useEffect(() => {
    if (user?.id) {
      fetchAgency(user.id);
    }
  }, [user?.id, fetchAgency]);

  const getPropertyTypeLabel = (type: Property['propertyType']) => {
    const labels = {
      property: 'Propriété',
      forest: 'Forêt',
      house: 'Maison',
      land: 'Terrain',
    };
    return labels[type];
  };

  const getTransactionTypeLabel = (type: Property['transactionType']) => {
    return type === 'sale' ? 'Vente' : 'Location';
  };

  const netSellerPrice = property.price - (property.agencyFees?.amount || 0);

  return (
    <PDFViewer style={{ width: '100%', height: '600px' }}>
      <Document>
        <Page size="A4" style={styles.page}>
          {agency && <AgencyHeader agency={agency} />}

          <Text style={styles.title}>BON DE VISITE</Text>
          <Text style={styles.subtitle}>Date : {today}</Text>

          <View style={styles.clause}>
            <Text>
              Je reconnais avoir visité le bien ci-dessus désigné et m'engage à ne pas traiter directement ou indirectement avec le propriétaire sans l'intermédiaire de l'agence. Je reconnais que toute violation de cet engagement m'exposerait au paiement de dommages et intérêts.
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Informations du bien</Text>
            <View style={styles.row}>
              <Text style={styles.label}>Référence :</Text>
              <Text style={styles.value}>{property.reference}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Type de transaction :</Text>
              <Text style={styles.value}>{getTransactionTypeLabel(property.transactionType)}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Type de bien :</Text>
              <Text style={styles.value}>{getPropertyTypeLabel(property.propertyType)}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Prix FAI :</Text>
              <Text style={styles.value}>{formatPrice(property.price)} €</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Honoraires d'agence :</Text>
              <Text style={styles.value}>{formatPrice(property.agencyFees?.amount || 0)} € TTC ({property.agencyFees?.percentage || 0}% du prix net vendeur)</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Prix net vendeur :</Text>
              <Text style={styles.value}>{formatPrice(netSellerPrice)} €</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Adresse :</Text>
              <Text style={styles.value}>
                {property.address.street}
                {'\n'}
                {property.address.postalCode} {property.address.city}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Surface habitable :</Text>
              <Text style={styles.value}>{formatNumber(property.livingSpace)} m²</Text>
            </View>
            {property.landArea > 0 && (
              <View style={styles.row}>
                <Text style={styles.label}>Surface terrain :</Text>
                <Text style={styles.value}>{formatNumber(property.landArea)} m²</Text>
              </View>
            )}
          </View>

          <View style={styles.visitInfo}>
            <Text style={styles.sectionTitle}>Informations visiteur</Text>
            <View style={styles.row}>
              <Text style={styles.label}>Nom et prénom :</Text>
              <Text style={styles.value}>................................................</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Adresse :</Text>
              <Text style={styles.value}>................................................</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Téléphone :</Text>
              <Text style={styles.value}>................................................</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Email :</Text>
              <Text style={styles.value}>................................................</Text>
            </View>
          </View>

          <View style={styles.signature}>
            <View>
              <Text>Signature du visiteur</Text>
              <View style={styles.signatureBox} />
            </View>
            <View>
              <Text>Signature de l'agent</Text>
              <View style={styles.signatureBox} />
            </View>
          </View>

          <Text style={styles.footer}>
            Ce document atteste de la visite du bien immobilier mentionné ci-dessus.
          </Text>
        </Page>
      </Document>
    </PDFViewer>
  );
}