import React from 'react';
import { useNavigate, Link, Routes, Route } from 'react-router-dom';
import { Building2, Settings, LayoutDashboard, LogOut, Globe, Mail, ChevronRight } from 'lucide-react';
import { AgencyManagement } from './AgencyManagement';
import { PortalGateway } from '../../components/admin/PortalGateway';
import { VisitFormSettings } from '../../components/admin/VisitFormSettings';
import { SMTPConfigPage } from './SMTPConfigPage';
import { useAuthStore } from '../../store/authStore';

export function DashboardPage() {
  const navigate = useNavigate();
  const { signOut } = useAuthStore();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-600 via-blue-400 to-purple-500 relative overflow-hidden">
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -left-4 top-1/4 w-24 h-24 bg-white/10 rounded-full blur-xl animate-float"></div>
        <div className="absolute right-10 top-1/3 w-32 h-32 bg-purple-300/20 rounded-full blur-xl animate-float-delayed"></div>
        <div className="absolute left-1/3 bottom-1/4 w-40 h-40 bg-blue-300/20 rounded-full blur-xl animate-float-slow"></div>
      </div>

      <div className="relative z-10">
        <div className="bg-white/10 backdrop-blur-md shadow-lg">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0 flex items-center">
                  <div className="w-10 h-10 bg-white rounded-lg shadow-lg flex items-center justify-center animate-bounce-gentle">
                    <LayoutDashboard className="h-6 w-6 text-blue-600" />
                  </div>
                  <span className="ml-3 text-xl font-bold text-white">Administration</span>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  <NavLink to="/admin">
                    <Building2 className="w-5 h-5 mr-2" />
                    Agences
                  </NavLink>
                  <NavLink to="/admin/portals">
                    <Globe className="w-5 h-5 mr-2" />
                    Portails
                  </NavLink>
                  <NavLink to="/admin/smtp">
                    <Mail className="w-5 h-5 mr-2" />
                    SMTP
                  </NavLink>
                  <NavLink to="/admin/settings">
                    <Settings className="w-5 h-5 mr-2" />
                    Paramètres
                  </NavLink>
                </div>
              </div>
              <div className="flex items-center">
                <button
                  onClick={handleSignOut}
                  className="flex items-center px-4 py-2 text-sm font-medium text-white hover:bg-white/10 rounded-lg transition-colors"
                >
                  <LogOut className="w-5 h-5 mr-2" />
                  Déconnexion
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="bg-white/90 backdrop-blur-xl rounded-2xl shadow-xl p-6 transform transition-all">
            <Routes>
              <Route path="/" element={<AgencyManagement />} />
              <Route path="/portals" element={<PortalGateway />} />
              <Route path="/smtp" element={<SMTPConfigPage />} />
              <Route path="/settings" element={<VisitFormSettings />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

function NavLink({ to, children }: { to: string; children: React.ReactNode }) {
  return (
    <Link
      to={to}
      className="inline-flex items-center px-4 py-2 text-sm font-medium text-white hover:bg-white/10 rounded-lg transition-colors"
    >
      {children}
      <ChevronRight className="w-4 h-4 ml-1 opacity-50" />
    </Link>
  );
}