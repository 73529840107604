import { create } from 'zustand';
import { collection, getDocs, doc, updateDoc, query, where, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Agency } from '../types/agent';
import { AdminSettings } from '../types/admin';
import { getAdminSettings, updateVisitFormClause } from '../lib/admin';
import { removeDuplicateClients, removeDuplicateProperties } from '../lib/cleanDatabase';

interface AdminStore {
  agencies: Agency[];
  settings: AdminSettings | null;
  loading: boolean;
  error: string | null;
  fetchAgencies: () => Promise<void>;
  updateAgencyStatus: (agencyId: string, isActive: boolean) => Promise<void>;
  updateAgencyPortals: (agencyId: string, portalId: string, active: boolean) => Promise<void>;
  fetchSettings: () => Promise<void>;
  updateSettings: (clause: string) => Promise<void>;
  cleanupDatabase: (agencyId: string, userId: string) => Promise<void>;
}

export const useAdminStore = create<AdminStore>((set, get) => ({
  agencies: [],
  settings: null,
  loading: false,
  error: null,

  fetchAgencies: async () => {
    set({ loading: true, error: null });
    try {
      const agenciesRef = collection(db, 'agencies');
      const snapshot = await getDocs(agenciesRef);
      const agencies = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        updatedAt: doc.data().updatedAt?.toDate(),
      })) as Agency[];
      set({ agencies, loading: false });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage, loading: false });
    }
  },

  updateAgencyStatus: async (agencyId: string, isActive: boolean) => {
    set({ loading: true, error: null });
    try {
      const agencyRef = doc(db, 'agencies', agencyId);
      await updateDoc(agencyRef, {
        isActive,
        updatedAt: Timestamp.now(),
      });

      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('agencyId', '==', agencyId));
      const snapshot = await getDocs(q);
      
      const userUpdates = snapshot.docs.map((doc) =>
        updateDoc(doc.ref, {
          isActive,
          updatedAt: Timestamp.now(),
        })
      );
      
      await Promise.all(userUpdates);

      set((state) => ({
        agencies: state.agencies.map((agency) =>
          agency.id === agencyId ? { ...agency, isActive } : agency
        ),
        loading: false,
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage, loading: false });
      throw error;
    }
  },

  updateAgencyPortals: async (agencyId: string, portalId: string, active: boolean) => {
    set({ loading: true, error: null });
    try {
      const agencyRef = doc(db, 'agencies', agencyId);
      await updateDoc(agencyRef, {
        [`portals.${portalId}.active`]: active,
        [`portals.${portalId}.updatedAt`]: Timestamp.now(),
      });

      set((state) => ({
        agencies: state.agencies.map((agency) =>
          agency.id === agencyId
            ? {
                ...agency,
                portals: {
                  ...agency.portals,
                  [portalId]: {
                    active,
                    updatedAt: new Date(),
                  },
                },
              }
            : agency
        ),
        loading: false,
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage, loading: false });
      throw error;
    }
  },

  fetchSettings: async () => {
    set({ loading: true, error: null });
    try {
      const settings = await getAdminSettings();
      set({ settings, loading: false });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage, loading: false });
    }
  },

  updateSettings: async (clause: string) => {
    set({ loading: true, error: null });
    try {
      await updateVisitFormClause(clause);
      set((state) => ({
        settings: state.settings ? { ...state.settings, visitFormClause: clause, updatedAt: new Date() } : null,
        loading: false,
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage, loading: false });
      throw error;
    }
  },

  cleanupDatabase: async (agencyId: string, userId: string) => {
    set({ loading: true, error: null });
    try {
      const deletedClients = await removeDuplicateClients(agencyId);
      const deletedProperties = await removeDuplicateProperties(userId);
      
      console.log(`Cleaned up ${deletedClients} duplicate clients and ${deletedProperties} duplicate properties`);
      
      // Refresh the data
      await get().fetchAgencies();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred';
      set({ error: errorMessage, loading: false });
    }
  },
}));