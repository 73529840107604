import React from 'react';
import { Property } from '../types/property';
import { X } from 'lucide-react';
import { VisitForm } from './VisitForm';

interface VisitFormModalProps {
  property: Property;
  isOpen: boolean;
  onClose: () => void;
}

export function VisitFormModal({ property, isOpen, onClose }: VisitFormModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-6xl max-h-[90vh] flex flex-col">
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-lg font-semibold">
            Bon de visite - {property.title}
          </h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        
        <div className="flex-1 p-4 overflow-auto">
          <VisitForm property={property} />
        </div>
      </div>
    </div>
  );
}