import React from 'react';
import { EnergyClass } from '../../types/property';
import { DPEDisplay } from './DPEDisplay';
import { calculateDPEClass, calculateGESClass } from '../../utils/dpeCalculations';

interface DPEInputProps {
  type: 'dpe' | 'ges';
  value: number;
  energyClass: EnergyClass;
  onChange: (value: number, energyClass: EnergyClass) => void;
}

export function DPEInput({ type, value, energyClass, onChange }: DPEInputProps) {
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === '' ? 0 : Number(e.target.value);
    if (!isNaN(newValue)) {
      const newClass = type === 'dpe' 
        ? calculateDPEClass(newValue)
        : calculateGESClass(newValue);
      onChange(newValue, newClass);
    }
  };

  const getLabel = () => {
    if (type === 'dpe') {
      return {
        title: 'Diagnostic de Performance Énergétique (DPE)',
        input: 'Consommation énergétique (kWhEP/m².an)'
      };
    }
    return {
      title: 'Émissions de Gaz à Effet de Serre (GES)',
      input: 'Émissions de GES (kgeqCO2/m².an)'
    };
  };

  const labels = getLabel();

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {labels.title}
        </label>
        <div className="mb-4">
          <DPEDisplay
            type={type}
            currentClass={energyClass}
            currentValue={value}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {labels.input}
          </label>
          <input
            type="number"
            value={value || ''}
            onChange={handleValueChange}
            min="0"
            step="1"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
      </div>
    </div>
  );
}