import React, { useEffect } from 'react';
import { useAdminStore } from '../../store/adminStore';
import { Portal } from '../../types/portal';
import { Building2, Mail, Phone, Globe, CheckCircle, XCircle } from 'lucide-react';
import { LoadingSpinner } from '../../components/LoadingSpinner';

const PORTALS: Portal[] = [
  { id: 'seloger', name: 'SeLoger', active: false },
  { id: 'leboncoin', name: 'Leboncoin', active: false },
  { id: 'logicimmo', name: 'Logic-Immo', active: false },
  { id: 'bienici', name: "Bien'ici", active: false },
  { id: 'superimmo', name: 'Superimmo', active: false },
  { id: 'monbien', name: 'monbien.fr', active: false },
  { id: 'repimmo', name: 'Repimmo', active: false },
];

export function AgencyManagement() {
  const { agencies = [], loading, error, fetchAgencies, updateAgencyStatus, updateAgencyPortals } = useAdminStore();
  const [updatingId, setUpdatingId] = React.useState<string | null>(null);

  useEffect(() => {
    fetchAgencies();
  }, [fetchAgencies]);

  if (loading && (!agencies || agencies.length === 0)) {
    return (
      <div className="flex justify-center items-center h-64">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
        {error}
      </div>
    );
  }

  const handleStatusUpdate = async (agencyId: string, isActive: boolean) => {
    try {
      setUpdatingId(agencyId);
      await updateAgencyStatus(agencyId, isActive);
    } finally {
      setUpdatingId(null);
    }
  };

  const handlePortalUpdate = async (agencyId: string, portalId: string, active: boolean) => {
    try {
      await updateAgencyPortals(agencyId, portalId, active);
    } catch (error) {
      console.error('Error updating portal status:', error);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Gestion des agences</h1>
      </div>

      <div className="grid gap-6">
        {agencies.map((agency) => (
          <div key={agency.id} className="bg-white shadow rounded-lg p-6">
            <div className="flex justify-between items-start">
              <div className="space-y-1">
                <h3 className="text-lg font-medium">{agency.name}</h3>
                <p className="text-sm text-gray-500">{agency.legalForm}</p>
              </div>
              <button
                onClick={() => handleStatusUpdate(agency.id, !agency.isActive)}
                disabled={updatingId === agency.id}
                className={`px-4 py-2 rounded-md flex items-center ${
                  agency.isActive
                    ? 'bg-red-50 text-red-600 hover:bg-red-100'
                    : 'bg-green-50 text-green-600 hover:bg-green-100'
                }`}
              >
                {updatingId === agency.id ? (
                  <LoadingSpinner size="sm" />
                ) : agency.isActive ? (
                  <>
                    <XCircle className="w-4 h-4 mr-2" />
                    Désactiver
                  </>
                ) : (
                  <>
                    <CheckCircle className="w-4 h-4 mr-2" />
                    Activer
                  </>
                )}
              </button>
            </div>

            <div className="mt-4 grid grid-cols-2 gap-4">
              <div>
                <h4 className="text-sm font-medium text-gray-500">Informations légales</h4>
                <div className="mt-2 space-y-1 text-sm">
                  <p>SIRET: {agency.siret}</p>
                  <p>RCS: {agency.rcs}</p>
                  <p>Carte Transaction: {agency.carteTransaction}</p>
                </div>
              </div>

              <div>
                <h4 className="text-sm font-medium text-gray-500">Contact</h4>
                <div className="mt-2 space-y-2">
                  <a
                    href={`mailto:${agency.email}`}
                    className="flex items-center text-sm text-gray-600 hover:text-blue-600"
                  >
                    <Mail className="w-4 h-4 mr-2" />
                    {agency.email}
                  </a>
                  <a
                    href={`tel:${agency.phone}`}
                    className="flex items-center text-sm text-gray-600 hover:text-blue-600"
                  >
                    <Phone className="w-4 h-4 mr-2" />
                    {agency.phone}
                  </a>
                  {agency.website && (
                    <a
                      href={agency.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-sm text-gray-600 hover:text-blue-600"
                    >
                      <Globe className="w-4 h-4 mr-2" />
                      Site web
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-4 pt-4 border-t">
              <h4 className="text-sm font-medium text-gray-500">Adresse</h4>
              <p className="mt-2 text-sm text-gray-600">
                {agency.address.street}
                <br />
                {agency.address.postalCode} {agency.address.city}
                <br />
                {agency.address.country}
              </p>
            </div>

            <div className="mt-4 pt-4 border-t">
              <h4 className="text-sm font-medium text-gray-500 mb-4">Portails</h4>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {PORTALS.map((portal) => {
                  const isActive = agency.portals?.[portal.id]?.active || false;
                  return (
                    <button
                      key={portal.id}
                      onClick={() => handlePortalUpdate(agency.id, portal.id, !isActive)}
                      className={`flex items-center justify-between p-3 rounded-md ${
                        isActive
                          ? 'bg-green-50 text-green-600 hover:bg-green-100'
                          : 'bg-red-50 text-red-600 hover:bg-red-100'
                      }`}
                    >
                      <span>{portal.name}</span>
                      {isActive ? (
                        <CheckCircle className="w-4 h-4" />
                      ) : (
                        <XCircle className="w-4 h-4" />
                      )}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}